import { Button, Card, Form, Input, Select, Space } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from "react";
import { connect } from "react-redux";
import * as actions from "../actions";
import { errorResponse, resetFormErrors, successResponse } from "./../../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "./../../../../utils/apiHelpers/formHelpers";
import { ErrorAlert, SuccessAlert } from "./../../../../utils/Components/formAlerts";
import { appConfigs } from "./../../../../utils/configurations";

const { Option } = Select;
interface Prop{
    oprList:any,
    oprMaint:Function,
    //oprsListRefreshOnUpdate: Function,
    context: any,
    oprMaintClose:Function,
    operatorOrgUsersGet:Function,
    getAssets:Function,
    getOprs: Function,
   // operatorOrgAccessTypesGet: Function
   // rfidSetValue: Function
}

interface State extends FormState {
    accessType: string;
    rfidValue: string;
    isOveridePanelDisplay: boolean;
    overrideErrorMessage: string;
    pinValue: any,
    isExistingUser: boolean,
}

class OprMaintInfo extends React.Component<Prop> {  
    state: State = {
        accessType: "",
        rfidValue: "",
        isOveridePanelDisplay: false,
        overrideErrorMessage: "",
        pinValue: "",
        isExistingUser: true
    };
    formRef = React.createRef<FormInstance>();
    componentDidMount() {
        this.componentUpdate(this.props.oprList.oprMaint.record);
    }
    componentDidUpdate(prevProps: Prop) {
         if (this.props.oprList.oprMaint.instance !== prevProps.oprList.oprMaint.instance) {
             this.componentUpdate(this.props.oprList.oprMaint.record);
        }
    }
    componentUpdate(record:any) {
        formInit(this);
        //let orgId = (record.orgId || "") === "" ? this.props.context.appContext.orgId:record.orgId;
        let orgId = 'a09ad270-3b21-11e7-893e-f94b4bc7246b';
        this.props.operatorOrgUsersGet(this, null, null,orgId);
        this.props.getAssets(this, null, null, 'all', orgId);
        
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();
            this.formRef.current!.setFieldsValue({
                orgId: (record.orgId || "") === "" ? (this.props.context.appContext.roleName === "admin" ? this.props.context.appContext.orgId: null) : record.orgId,    
                operatorIdValue:record.operatorIdValue,
                accessType:record.accessType,
                accessCardValue:record.accessCardValue,
                userId:record.userId,
                assetName:record.assetName,
                accessCardId:record.accessCardId,
                pin: record.pin,
                mastertags: record.mastertags?.map((assetTag:any) => {return {value: assetTag.mac, label: assetTag.mac, key: assetTag.mastertag} }),
                id:record.id,
                isExistingUser: (this.state.isExistingUser)?'yes':'no'
              
            });
        }
    }
   
    onFinish = (values: any) => {
        resetFormErrors(this, this.formRef.current);
        const { orgAssets } =this.props.oprList.oprMaint.tabs[0];
        let filteredAssets: any[] = [];
        
            values.mastertags.map((mastertag: any) => {
                orgAssets.map((asset:any) => {
                    if (asset.serialNumber === mastertag.value ){
                        //filteredAssets.push(asset);
                        filteredAssets.push({key:asset.mastertag , label:asset.serialNumber , value:asset.mastertag });
                        return
                    }
                
            });
            });
            values.mastertags= filteredAssets;
            // return user.age > 40 && user.occupation === 'programmer';
        
        this.props.oprMaint(this, this.onFinishSuccess, this.onFinishFailure, values);
    };

    onFinishSuccess = (record: any) => {
        
        this.props.getOprs(this, null, null, this.props.oprList.tableConfig.pagination.current, this.props.oprList.searchText);
        if (record != null && record.operatorIdValue != null && 
            Object.keys(record.operatorIdValue) != null && Object.keys(record.operatorIdValue).length !== 0) {
            this.componentUpdate(record);
        }
        this.setState({ isOverrideRFID: false, isOveridePanelDisplay: false, overrideErrorMessage: "" });
        successResponse(this, "Operator has been successfully processed");
    }
    onFinishFailure = (error: any) => { 
        if (error.response.data.errors[0].reference === "override") {
            const errMsg = error.response.data.errors[0].message;
            this.setState({ isOveridePanelDisplay: true, overrideErrorMessage: errMsg });
        }
        else {
            this.setState({ isOverrideRFID: false, isOveridePanelDisplay: false, overrideErrorMessage: "" });
            errorResponse(this, error);
        }
    }

    onOverrideSubmit = (e: any) => {
        this.setState({ isOverrideRFID: true });
        this.formRef.current?.submit();
    }
    cancelOverride = (e: any) => {
        this.setState({ isOverrideRFID: false, isOveridePanelDisplay: false, overrideErrorMessage: "" });
    } 
    
    onAccessTypeChange = (value:any) => {
        this.setState({ accessType: value });
        if (value.toLowerCase() !== 'rfid') {
            this.setState({ rfidValue: ""})
            // this.props.rfidSetValue(this, "");
        }
    };

    handleUserTypeChange = (value: string) => {
        if(value.toLowerCase()==='yes')
        {
            this.setState({isExistingUser: true});
        }
        else
        {
            this.setState({isExistingUser: false});
        }
    };

    onInputRfidChange = (value:any) => {
        this.setState({ rfidValue: value || "" == ""? "" : value.target.value});
      //  this.props.rfidSetValue(this, value || "" == ""? "" : value.target.value);
    };

    render(){
         const { orgUsers, orgAssets } = this.props.oprList.oprMaint.tabs[0];
         const { mastertags } = this.props.oprList.oprMaint.record;
 
        return(
            <div  data-id="opr-maint-info">
                 {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="oprs-maint-info-success-message"  message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="oprs-maint-info-error-message" message={this.state.formErrorMessage}/>}

                {
                    this.state.isOveridePanelDisplay &&

                    <Card style={{ height: '150px', backgroundColor: '#d9d9d94d' }}>
                        <p>{this.state.overrideErrorMessage}.</p>
                        <p>Do you want to override operator?</p>
                        <Space size="large" style={{ float: 'right' }}>
                            <Button data-id="opr-maint-close-button" type="default" onClick={(e) => this.cancelOverride(e)}>
                                No
                </Button>
                            <Button data-id="opr-maint-submit-button" type="primary" form="oprMaintInfoForm" key="submit" onClick={(e) => this.onOverrideSubmit(e)}>
                                Yes
                  </Button>

                        </Space>
                    </Card>

                }
           <Form
            {...appConfigs.settings.formLayout.mainLayout}
            id="oprMaintInfoForm"
            ref={this.formRef}
            onFinish={this.onFinish}
            className="mt-15"
        >
           <Form.Item
                name="id"
                style={{ display: 'none' }}
            >
                <Input type="hidden" />
            </Form.Item>
            <Form.Item
                name="accessCardId"
                style={{ display: 'none' }}
            >
                <Input type="hidden" />
            </Form.Item>
            {/* <Form.Item
                name="assetId"
                style={{ display: 'none' }}
            >
                <Input type="hidden" />
            </Form.Item> */}
                         
            <Form.Item
                label="Operator ID"
                name="operatorIdValue"
                rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
            >
                <Input maxLength={50}/>
            </Form.Item>

             {/* <Form.Item
                label="Access Type"
                name="accessType"
                // rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
            >
                 <Select 
                            showSearch
                            showArrow={true}
                            allowClear
                            placeholder="Select..."
                            optionFilterProp="children"
                            className="select-before"
                            onChange={this.onAccessTypeChange}
                        >

                       {
                        orgAccessTypes !== null && orgAccessTypes.map((accessType:any, index:number) => {
                            return (
                            <Option key={index} value={accessType}>
                                {accessType}
                            </Option>
                            )})}
                        </Select>
                            
                                                                                                 
            </Form.Item> */}
                {
                   
                    <Form.Item
                    label="RFID"
                    name="accessCardValue"
                    //rules={[{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }]}
                    >
                    <Input value = {this.state.rfidValue} onChange={this.onInputRfidChange} maxLength={10}/>
                    </Form.Item>

            }
              {
                   
                   <Form.Item
                   label="PIN"
                   name="pin"
                   rules={[{ required: false, message: "Enter a 4 digit numeric value",
                    pattern: /^\d{4}$/
                    }]}
                   >
                   <Input value = {this.state.pinValue} />
                   </Form.Item>

           }
           {
            <Form.Item
                        name="isExistingUser"
                        label="Existing User"
                        rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
                     >
                        <Select
                            showArrow={true}
                            onChange={this.handleUserTypeChange} 
                           // defaultValue= {(this.state.isExistingUser)?'yes':'no'}
                        >
                                <Option key="yesExisting" value="yes">
                                    Yes
                                </Option>
                                <Option key="noExisting" value="no">
                                    No
                                </Option>
                           
                        </Select>
                    </Form.Item>
            }
            {
                this.state.isExistingUser &&
                    <Form.Item
                        name="userId"
                        label="User"
                        rules={[{ required: this.state.isExistingUser, message: appConfigs.errors.fieldErrors.valueRequired }]}
                     >
                        <Select
                            showSearch
                            showArrow={true}
                            allowClear
                            placeholder="Select..."
                            optionFilterProp="children"
                            className="select-before"
                                                    
                        >
                            {orgUsers !== null && orgUsers.map((record:any, index:number) => (
                                <Option key={record.userId} value={record.userId}>
                                    {record.fullName}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
            }
            {
                !this.state.isExistingUser &&
                <Form.Item
                label="First Name"
                name="userFirstName"
                rules={[{ required: !this.state.isExistingUser, message: appConfigs.errors.fieldErrors.valueRequired },
                        {message: "Enter a valid first name. Special characters not allowed. Name shall start with an alphabet", pattern:/^[a-zA-Z\s]+[0-9\s]*$/}
                ]}
                >
                    <Input maxLength={50}/>
                </Form.Item>
            }
             {
                !this.state.isExistingUser &&
                <Form.Item
                label="Last Name"
                name="userLastName"
                rules={[{ required: !this.state.isExistingUser, message: appConfigs.errors.fieldErrors.valueRequired },
                    {message: "Enter a valid last name. Special characters not allowed. Name shall start with an alphabet", pattern:/^[a-zA-Z\s]+[0-9\s]*$/}
                ]}
                >
                    <Input maxLength={50}/>
                </Form.Item>
            }
             {
                !this.state.isExistingUser &&
                <Form.Item
                label="Phone"
                name="userPhone"
                rules={[
                    { required: !this.state.isExistingUser, message: appConfigs.errors.fieldErrors.valueRequired },
                    {  message: "Enter a valid phone number",
                        pattern: /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/ }
                ]}
                >
                    <Input maxLength={50} placeholder='+91 1234567890 or 1234567890 or 123-456-7890 or (123) 456-7890'/>
                </Form.Item>
            }
             {
                !this.state.isExistingUser &&
                <Form.Item
                label="Email"
                name="userEmail"
                rules={[
                    { required: true, message: appConfigs.errors.fieldErrors.valueRequired },
                    { type: "email", message: appConfigs.errors.fieldErrors.invalidEmail}
                ]}
                >
                    <Input maxLength={50}/>
                </Form.Item>
            }
                    <Form.Item
                    name="assetName"
                    label="Asset Last Accessed"
                    style={{display:(this.props.oprList.oprMaint.record.assetName||"")==""?'none':''}}
                    >
                     <Input disabled maxLength={32}/>
                    </Form.Item>

                <Form.Item
                    label="Controlled Assets"
                    name="mastertags"
                
                    initialValue={mastertags == null ? [] : mastertags?.map((asset: any) => {
                             return { value: asset.mac, label: asset.mac, key: asset.mastertag }
                             })}
                >
                <Select

                        mode="multiple"
                        style={{ width: '100%'}}
                        placeholder="Select Serial Number"
                        // disabled={((this.state.accessType ||"") === "RFID" ||
                        //     (accessType === "RFID" && (this.state.accessType || "").toLowerCase() !== "asset-mapping")) ||
                        //     this.props.oprList.oprMaint.record.status == 'inactive' }
                         labelInValue
                        showSearch
                        showArrow={true}
                        allowClear
                        // optionFilterProp="children"
                        className="select-before" 
                                                
                    >
                        {orgAssets !== null && orgAssets.map((asset:any, index:number) => {
                            return (
                            <Option key={asset.mastertag} value={asset.serialNumber}>
                                {asset.serialNumber}
                            </Option>
                        )})}
                </Select>
                </Form.Item>
            </Form>
            
            </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        oprList: state.taylorOprReducers.oprState.oprList
    };
};

export default connect(
    mapStateToProps,
    actions
)(OprMaintInfo);