import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { inputSignalMapper, signalLabel } from "../common";
import { CommonWidgetProps } from "../../models";
import {
  Map,
  Marker,
  MapActions,
} from "../../../../sharedComponents/GoogleMap";
import { toolTipForAssetEvents } from "./Tooltip";
// import MapPin from "../../../../assets/images/map-pin.png";
import { Tooltip, Button, Empty, Row, Col } from "antd";
import { AppstoreOutlined, BorderOuterOutlined, FilterOutlined } from "@ant-design/icons";
import { toolTipForAsset } from "../LocationWidget/Tooltip";
import useGoogleMapsLib from "../../../../sharedComponents/GoogleMap/use-google-maps-lib";
import { getDistance, getGPSEventsWidgetIconDetails } from "../../../../utils/commonHelpers";
import MapMenuOptions from "./MapMenuOptions";
import { WidgetState } from "../../state";
import { connect } from "react-redux";
import * as actions from "../../../../actions";


interface Signal {
  id: string;
  name: string;
  color?: string;
  values: Array<{
    timestamp: Date;
    value: number;
    severity?: string;
    ruleName?: string;
    uom?: string;
  }>;
}


interface ObjectKeys {
  [key: string] : string | boolean | SignalMapOptions[];
}
export interface SignalMapOptions extends ObjectKeys {
  EnableSignal: boolean;
  ShowSignalMarker: boolean;
  SignalId: string;
  name: string;
  color: string;
}


export interface EventsMapMarkerOptions extends ObjectKeys {
  ShowGPSPath: boolean;
  ShowGPSMarker: boolean;
  ShowStartEndPoint: boolean;
  HideClusteredPoints: boolean;
  SignalMapOptions: SignalMapOptions[]
}


function assetToMarker(signals: Signal[], mapMarkerOptions?: EventsMapMarkerOptions,timezone?:any): Marker[] {

  const latitude = signals.filter((s) => s.name.toLowerCase().indexOf('latitude') != -1)[0] || [];
  const longitude = signals.filter((s) => s.name.toLowerCase().indexOf('longitude') != -1)[0] || [];
  let markerArray = signals.filter((s) => {
    return !s.name.toLowerCase().includes('latitude') && !s.name.toLowerCase().includes('longitude')
  });

  if (latitude?.values?.length > 0 && typeof google !== 'undefined') {

    const GPSMarkers: Marker[] = [];
    latitude.values.forEach((s, i) => {
      const timestamp = s.timestamp;
      const lat = latitude.values[i].value;
      const lng = longitude.values[i].value;

      let point3;
      let point2;
      let point1 = new google.maps.LatLng(lat, lng);
      latitude.values.length != i + 1 ?
        point2 = new google.maps.LatLng(latitude.values[i + 1].value, longitude.values[i + 1].value)
        : point2 = point1;

      /* This is added as an additional check for slow moving vehicle */
      latitude.values.length > i + 4 ?
        point3 = new google.maps.LatLng(latitude.values[i + 4].value, longitude.values[i + 4].value)
        : point3 = point1;
      
      let distanceInMeters = getDistance(point1, point2);
      let distanceInMetersAfterThreePoints = getDistance(point1, point3);
      
      let marker: Marker;

      marker = {
        id: `${i}`,
        position: {
          lat: lat,
          lng: lng,
        },
        timestamp: timestamp,
        tooltip: i == 0 ? toolTipForAsset({ lat, lng }, timestamp, 'Starting Point',timezone) : i == latitude.values.length - 1 ? toolTipForAsset({ lat, lng }, timestamp, 'End Point',timezone) : toolTipForAsset({ lat, lng }, timestamp,undefined,timezone),
        optimized: true,
        show: mapMarkerOptions?.ShowGPSMarker ? mapMarkerOptions?.ShowGPSMarker : false,
        enabled: true,
      };

      /* Handling Showing Start/End Points and Customizing Tooltip and label */
      if (mapMarkerOptions?.ShowStartEndPoint) {
        if (i == 0) {
          marker.tooltip = toolTipForAsset({ lat, lng }, timestamp, 'Starting Point',timezone)
          marker.show = mapMarkerOptions?.ShowStartEndPoint ? mapMarkerOptions?.ShowStartEndPoint : false
          marker.enabled = mapMarkerOptions?.ShowStartEndPoint ? mapMarkerOptions?.ShowStartEndPoint : false
          marker.label = {
            text: mapMarkerOptions?.ShowStartEndPoint ? 'S' : '',
            color: '#fff',
            fontWeight: 'bold',
          }
        } else if (i == latitude.values.length - 1) {
          marker.tooltip = toolTipForAsset({ lat, lng }, timestamp, 'End Point',timezone)
          marker.show = mapMarkerOptions?.ShowStartEndPoint ? mapMarkerOptions?.ShowStartEndPoint : false
          marker.enabled = mapMarkerOptions?.ShowStartEndPoint ? mapMarkerOptions?.ShowStartEndPoint : false
          marker.label = {
            text: mapMarkerOptions?.ShowStartEndPoint ? 'E' : '',
            color: '#fff',
            fontWeight: 'bold',
          }
        } else {
          marker.icon = getGPSEventsWidgetIconDetails(point1, point2, 'GPS')
        }
      } else {
        marker.icon = getGPSEventsWidgetIconDetails(point1, point2, 'GPS')
      }
  
      /* Filtering Stationary Points */
      if (i == 0 || i == latitude.values.length - 1) {
        GPSMarkers.push(marker)
      } else if (mapMarkerOptions?.HideClusteredPoints) {
        if (distanceInMeters > 1.5 && distanceInMetersAfterThreePoints > 4) {
          GPSMarkers.push(marker)
        }
      } else {
        GPSMarkers.push(marker)
      }

    });

    /* Handling Linking Signal Events to nearest GPS point */
    let locKey = -1;
    let closestTimestamp: any;
    let EventsMarkers: any[] = [];
    markerArray?.map((alert, y) => {

      let foundIndex = mapMarkerOptions?.SignalMapOptions?.findIndex(x => x.SignalId == alert.id);

      if (foundIndex == undefined || mapMarkerOptions?.SignalMapOptions[foundIndex]?.EnableSignal || mapMarkerOptions?.SignalMapOptions[foundIndex]?.ShowSignalMarker) {

        var onColor = "#008000";
        var offColor = "#FF0000";

        if (alert) {
          if (alert.color) {
            let arr = alert.color.split("$");
            onColor = arr[0];
            offColor = arr[1];
          }
        }
        
        let lastValue: boolean;
        let skip = true;
        return alert.values.map((v, i) => {

          if (i == 0) {
            skip = false;
            lastValue = !!v.value
          }

          if (!!v.value == lastValue && i != 0) {
            skip = true; lastValue = !!v.value
          } else {
            skip = false; lastValue = !!v.value
          }

          if (!skip) {

            locKey = -1
            closestTimestamp = v.timestamp;
            GPSMarkers.forEach((loc, x) => {
              if (new Date(closestTimestamp) <= new Date(loc.timestamp) && locKey == -1) {
                locKey = x
              }
            });

            if (locKey == -1) {
              closestTimestamp = v.timestamp;
              GPSMarkers.forEach((loc, x) => {
                if (new Date(closestTimestamp) >= new Date(loc.timestamp)) {
                  locKey = x
                }
              });
            }

            if (locKey == -1) { locKey = 0 }

            const lat = GPSMarkers[locKey].position.lat;
            const lng = GPSMarkers[locKey].position.lng;

            let point2;
            let point1 = new google.maps.LatLng(lat, lng);
            GPSMarkers.length != locKey + 1 ?
              point2 = new google.maps.LatLng(GPSMarkers[locKey + 1].position.lat, GPSMarkers[locKey + 1].position.lng)
              : point2 = point1
            
            EventsMarkers.push({
              id: `${alert.name}-${locKey}-${y}-${i}`,
              position: {
                lat: lat,
                lng: lng,
              },
              value: v.value != undefined,
              color: !!v.value ? onColor : offColor,
              timestamp: GPSMarkers[locKey].timestamp,
              tooltip: toolTipForAssetEvents({ lat, lng }, alert.name, GPSMarkers[locKey].timestamp, v.timestamp, v.value, alert.name? alert.name : "N/A",timezone),
              icon: getGPSEventsWidgetIconDetails(point1, point2, v.severity ? v.severity?.toLowerCase() : undefined, !!v.value ? onColor : offColor),
              optimized: true,
              show: foundIndex == undefined ? true : mapMarkerOptions?.SignalMapOptions[foundIndex]?.ShowSignalMarker ? mapMarkerOptions.SignalMapOptions[foundIndex]?.ShowSignalMarker : false,
              enabled: foundIndex == undefined ? true : mapMarkerOptions?.SignalMapOptions[foundIndex]?.EnableSignal ? mapMarkerOptions.SignalMapOptions[foundIndex]?.EnableSignal : false,
              signalId: alert.id
              
            })

          }
                 });
      }

    });

    const EventsMarkersFlattened = EventsMarkers.flatMap((res: any) => res);
    const markersFinal = GPSMarkers.concat(EventsMarkersFlattened)

    //sort by timestamp
    const markersFinalSorted = markersFinal?.sort(function (a, b) {
      return (a?.timestamp && b?.timestamp) ? (a.timestamp < b.timestamp ? -1 : 1) : 1;
    });

    return markersFinalSorted;

  }

  return []

}

const createSignals = inputSignalMapper<Signal>((meta, data) => ({
  id: meta.id,
  name: signalLabel(meta, false),
  color: meta.color,
  values: data,
}));

const Widget: React.FC<CommonWidgetProps> = (props) => {

  useGoogleMapsLib();
  const { template, data, state } = props;

  let signals = createSignals(props.signals, template, data);

  /* updating with live value if present (SignalR) */
  if (Object.keys(props.signalRData.data).length > 0) {
    signals = signals.map((signal: Signal) => {
      if (props.signalRData.data.hasOwnProperty(signal.id)) {
        props.signalRData.data[signal.id].forEach((signalData:any, index: number) => {
          signal.values.push({
            value: signalData.value,
            timestamp: new Date(signalData.bt - signalData.time)
          })
        });
      }
      return signal
    })
  }

  const latitude = signals.filter((s) => s.name.toLowerCase().indexOf('latitude') != -1)[0] || [];
  const longitude = signals.filter((s) => s.name.toLowerCase().indexOf('longitude') != -1)[0] || [];

  let signalDetails = template.signalCollections.map((sc) => sc.signals.map((scs) => scs)).flat()

  let signalOptions: SignalMapOptions[]  = props.signals.filter((s) => {
    return !s.id.includes(latitude.id) && !s.id.includes(longitude.id)
  }).map((s, index) => {
    return {
      EnableSignal: index == 0 ? true : false,
      ShowSignalMarker: index == 0 ? true : false,
      SignalId: s.id,
      name: signalDetails.filter((sd) => sd.id == s.id)[0].name,
      color: s.color ? s.color : '#008000$#FF0000'
    }
  });

  let initialMapOptions: EventsMapMarkerOptions = {
    ShowGPSPath: true,
    ShowGPSMarker: false,
    ShowStartEndPoint: true,
    HideClusteredPoints: true,
    SignalMapOptions: signalOptions
  }


  const [mapActions, setMapActions] = useState<MapActions>();
  const [mapMarkerOptions, setEventsMapMarkerOptions] = useState<EventsMapMarkerOptions>(initialMapOptions);
  const [markers, setMarkers] = useState<Marker[]>(assetToMarker(signals, mapMarkerOptions,state.timezone));

  useEffect(() => {
    setEventsMapMarkerOptions(initialMapOptions)
    setMarkers(assetToMarker(signals, mapMarkerOptions,state.timezone))
  }, [data, template, props.signalRData.data])

  useEffect(() => {
    setMarkers(assetToMarker(signals, mapMarkerOptions,state.timezone))
  }, [mapMarkerOptions])
  
  

  // const memoizedCallback = useCallback((mapMarkerOptions) => { functionToRun(mapMarkerOptions) }, [MapMenuOptions])

  const functionToRun = (mapMarkerOptions: EventsMapMarkerOptions) => {
    setEventsMapMarkerOptions(mapMarkerOptions);
    setMarkers(assetToMarker(signals, mapMarkerOptions,state.timezone))
  }

  return (
    <div style={{ height: "100%", position: "relative" }}>
      {latitude?.values?.length > 0 ? (
        <>
          <Map
            data-id="map-container"
            markers={markers}
            clusterMarkers={false}
            eventsOnMap={true}
            mapActionsRef={setMapActions}
            mapOptions={{ mapTypeId: "satellite" }}
            polyline={false}
            drawOverlays={false}
            eventsMapMarkerOptions={ mapMarkerOptions }
          ></Map>

          <MapMenuOptions {...props} markers={ assetToMarker(signals) } mapMarkerOptions={ mapMarkerOptions } filterMapOption={functionToRun} mapActions={ mapActions } />
          
        </>

      ) : (
          <></>
        // <Row style={{ padding: '10px' }} justify="center" align="middle" gutter={[4, 4]}>
        //   <Col span={24}>
        //     <Empty
        //       description={
        //         <>
        //           <span> No data found for the selected time range</span><br />
        //           <span> Try to select different data range</span>
        //         </>
        //       }
        //     >
        //     </Empty>
        //   </Col>
        // </Row>
      )}
    </div>
  );
};

// export default memo(Widget);
const mapStateToProps = (state: any) => {
  return {
    signalRData: state.contextReducer.signalRData,
  };
};
export default connect(
  mapStateToProps,
  // actions
)(React.memo(Widget));
