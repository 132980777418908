import Axios from "axios";
import { errorResponse } from "./../../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "./../../../../utils/configurations";

export function getQbList(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    sortOrder: string,
    sortColumn: string,
    page: number = 1,
    qbId?:string,
    searchText?: string,
   
) {
    
    return async (dispatch: any) => {
        console.log("appConfigs.server.URL: "+appConfigs.server.URL);
        Axios.get(
            appConfigs.server.URL + "/ui/api/questionBanks", {
                responseType: "json",
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize,
                    qbId: (qbId||""),
                    sortOrder: sortOrder,
                    sortColumn: sortColumn,
                }
            }
        )
        .then(response => { 
                let data = response.data.data.questionBanks == null || (response.data.data.questionBanks.length || 0) === 0 ? [] : response.data.data.questionBanks;
 
                // data.forEach((item: any, index: number) => {
                //     data[index]['mastertags'] = JSON.parse(data[index]['mastertags'])
                //     // data[index]['mastertags'] = data[index]['mastertags']?.sort(sortAlphabetically)
                // });
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "QB_GET_SUCCESS", payload: {data: data, page: page} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function qbMaintOpen(
    me: any, 
    title: string,
    width: number,
    record: any
) {
    return (dispatch: any) => {
        
        dispatch({ type: "QB_MAINT_OPEN", payload: {title: title, width: width, record: record} });
    };
}

export function qbMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "QB_MAINT_CLOSE" });
    };
}

export function qbSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "QB_SET_SEARCH", searchText: searchText });
    }
}   

export function qbSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "QB_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}
