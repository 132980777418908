import React from "react";
import { Drawer, Tabs, Button, Space } from "antd";
import { connect } from "react-redux";
import * as actions from "./actions";
import { appConfigs } from "./../../../utils/configurations";
import OprMaintInfo from "./OprMaintInfo/OprMaintInfo";
import history from './../../../utils/history';

const {TabPane}=Tabs;

interface Prop{
    oprList: any,
    getOprs: Function,
    oprMaintClose:Function,
}

class oprMaint extends React.Component<Prop> {  
    componentDidUpdate(prevProps: Prop) {
        if (this.props.oprList.oprMaint.instance !== prevProps.oprList.oprMaint.instance) {
            this.closeCollapsed();
        }
    }    

onDrawerClose = () => {
    
    if (this.props.oprList.refreshOnUpdate) {
         //history.push("/operators");
        this.props.getOprs(this, null, null, this.props.oprList.tableConfig.pagination.current, this.props.oprList.searchText);
    }
       this.props.oprMaintClose(this);       
};

closeCollapsed() {       
    let active:any = document.querySelectorAll("[data-id=opr-maint-drawer] .ant-collapse-item-active");    
    if (active.length) {
        for (var i = 0; i < active.length; i++) { 
            active[i]!.children[0].click();  
        }
    }
}

render(){
    const { oprMaint } = this.props.oprList;
  
    return(
        <Drawer
        data-id="opr-maint-drawer" 
        title={oprMaint.title}
        width={oprMaint.width}
        onClose={this.onDrawerClose}
        visible={oprMaint.visible}
        destroyOnClose={true}
        footer={
            <Space size="large" style={{ float: 'right' }}>
                <Button data-id="opr-maint-close-button" type="default" onClick={this.onDrawerClose}>
                    {appConfigs.settings.drawers.closeButtonText}
                </Button>
                {
                    oprMaint.activeTab === "1" && ! (oprMaint.title.toString().toLowerCase().includes('history')) &&
                    <Button data-id="opr-maint-submit-button" type="primary" form="oprMaintInfoForm" key="submit" htmlType="submit">
                        {appConfigs.settings.form.submitButtonText}
                    </Button>
                }
            </Space>
        }
    >
        <Tabs data-id="users-maint-info-tabs" activeKey={oprMaint.activeTab} size="large" type="card">
            {/* {
                oprMaint.title.toString().toLowerCase().includes('history') &&
                <TabPane data-id="opr-maint-info-tab-button" tab={oprMaint.tabs[0].title} key="1">                    
                    <OprHistory/>
                </TabPane>
            } */}
            {
            !(oprMaint.title.toString().toLowerCase().includes('history')) &&
            <TabPane data-id="opr-maint-info-tab-button" tab={oprMaint.tabs[0].title} key="1">                    
               <OprMaintInfo />
            </TabPane>
            }  
        </Tabs>            
    </Drawer>
    );
}
}

const mapStateToProps = (state:any) => {
    return {
        oprList: state.taylorOprReducers.oprState.oprList
    };
};

export default connect(
    mapStateToProps,
    actions
)(oprMaint);
