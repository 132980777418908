import Axios from "axios";
import { errorResponse } from "./../../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "./../../../../utils/configurations";


export function getOprs(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    sortDirection: any,
    sortColumn: any,
    page: number = 1,
    searchText?: string,
    operatorId?:string
   
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/taylorOperators", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize,
                    operatorId: (operatorId||""),
                    sortOrder: sortDirection,
                    sortColumn: sortColumn
                }
            }
        )
        .then(response => { 
                let data = response.data.data.operators == null || (response.data.data.operators.length || 0) === 0 ? [] : response.data.data.operators;

                data.forEach((item: any, index: number) => {
                    data[index]['mastertags'] = JSON.parse(data[index]['mastertags'])
                    // data[index]['mastertags'] = data[index]['mastertags']?.sort(sortAlphabetically)
                });
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "TAYLOR_OPRS_GET_SUCCESS", payload: {data: data, page: page} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function getOperatorsHistory(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    sortDirection: any,
    sortColumn: any,
    page: number = 1,
    searchText?: string,
    operatorId?:string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/taylorOperatorsHistory", {
                responseType: "json",
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize,
                    operatorId: (operatorId||""),
                    sortOrder: sortDirection,
                    sortColumn: sortColumn
                }
            }
        )
        .then(response => {
                let data = (response.data.data.operatorsHistory == null ||
                        (response.data.data.operatorsHistory.length || 0) === 0 ) ? [] : response.data.data.operatorsHistory;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.operatorsHistory);
                }
                //data = data.map((tag: any) => tag['mastertag']).filter((item: any, i: any, ar: any) => ar.indexOf(item) === i).sort();
    
                dispatch({ type: "GET_OPR_HISTORY", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function getOpr(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    operatorId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/operators/id/" + operatorId, {
                responseType: "json",
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.operator);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}

export function oprMaintOpen(
    me: any, 
    title: string,
    width: number,
    record: any
) {
    return (dispatch: any) => {
        dispatch({ type: "OPRS_OPR_MAINT_OPEN", payload: {title: title, width: width, record: record} });
    };
}

export function oprMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "OPRS_OPR_MAINT_CLOSE" });
    };
}

export function oprSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "OPR_SET_SEARCH", searchText: searchText });
    }
}

export function oprHistorySetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "OPR_HISTORY_SET_SEARCH", searchText: searchText });
    }
}   


export function oprSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "OPRS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
}