import { DeleteOutlined, EditOutlined, EllipsisOutlined, PlusOutlined, UserAddOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { Button, Col, Input, Popconfirm, Popover, Row, Space, Table, Tag, Tooltip, message, notification } from "antd";
import React, { Component } from "react";
import BackNavigation from "./../../../utils/Components/BackNavigation";
import history from './../../../utils/history';
//import "./oprs.css";
import { connect } from "react-redux";
import { appConfigs } from "./../../../utils/configurations";
import * as actions from "./actions";
//import OprMaint from "./oprMaint";
import { CSVLink } from "react-csv";
import { closePopover, getPermissions, getToken } from "./../../../utils/commonHelpers";
import OprMaint from "./oprMaint";

const { Search } = Input;

interface Prop{
    oprSetSearch: Function,
    oprSetPagination:Function,
    getOprs: Function,
    oprMaintOpen: Function,
    context: any,
    oprList: any,
    oprMaint:Function,
    operatorDelete: Function,
    permissions: any
}

interface State {
    csvData: any,
    csvFileName: string,
    isOperator?: boolean,
    sortColumn: string | undefined,
    sortDirection: string | undefined,
    page: Number
}

class TaylorOperators extends Component<Prop, State> {
    state: State = {
        csvData: [],
        csvFileName: "",
        isOperator:true,
        sortColumn: "operatoridvalue",
        sortDirection: "ascend",
        page:1
    };

    drawerWidth: number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    csvLink: any = React.createRef<CSVLink>(); 

    getValuesFromPath=(index:number)=>{
        const pathArr = history.location.pathname.split("/");
        return pathArr[index];
    }
    getStateFromHistory=()=>{
      return  ((history.location.state || true) as State).isOperator;
    }

    componentDidMount(){
    // if (!this.props.context.user.contextUserIsAdmin) {
      //  this.props.oprSetPagination(this.onPaginationChange);
        this.getOprs();
        
    }

    getOprs() {
        const isOperator = this.getStateFromHistory(); //checking operator false or true
        const primaryKey = this.getValuesFromPath(3) //primary key
        const value = this.getValuesFromPath(2) //value
        if ((primaryKey||"") === "") {
           this.props.getOprs(this,this.getOprSuccess, null,  this.state.sortDirection, this.state.sortColumn?.toLowerCase(), this.state.page, this.props.oprList.searchText,null);
        }
        else {
            // this.props.oprSetSearch(value);
            if(!isOperator)
            {
                //incase of RFID passing clearing table data and passing accesscardvalue and operatorhistoryod
                let obj = { accessCardValue: "", operatorsHistoryId: ""};
                obj.accessCardValue = this.getValuesFromPath(2);
                obj.operatorsHistoryId =this.getValuesFromPath(3);
            //    this.props.clearTableData();
                this.oprMaintOpenDo(obj);
            }
            else
            {
                this.props.getOprs(this, this.getOprSuccess, null,  this.state.sortDirection, this.state.sortColumn?.toLowerCase(), this.state.page, null, null);
            }
           
        }
    }

    getOprSuccess = (data: any) => {
       //this.oprMaintOpenDo((data[0]||[]));
    }

    oprMaintOpenDo = (record: any) => {
        const title = Object.keys((record)).length === 0 ? "New Operator" : (record.operatorIdValue || "") === "" ? "New Operator" : "Edit Operator ::" + record.operatorIdValue;
        this.props.oprMaintOpen(this, title, this.drawerWidth, record);
    }

   onPaginationChange = (page: number) => {
        this.props.getOprs(this, null, null, page, this.props.oprList.searchText);
   };

   onAction = (e:any, record:any, action:string) => {
    e.preventDefault();
    if ( action === "add-opr" ) {
        this.props.oprMaintOpen(this, "New Operator", this.drawerWidth,{});
    }
    else if ( action === "edit-opr" ) {
       this.props.oprMaintOpen(this, "Edit Operator :: " + record.operatorIdValue, this.drawerWidth, record);
    }
    else if ( action === "history-opr" ) {
        this.props.oprMaintOpen(this, "Show History :: " + record.operatorIdValue, this.drawerWidth, record);
     }
    else if (action === "delete") {
        this.props.operatorDelete(this, this.operatorDeleteSucess, null, record.id);  
    }  

    closePopover();  
           
  }

  operatorDeleteSucess = (data:any) => {
    message.success("Operator has been successfully deleted");
    this.onPaginationChange(this.props.oprList.tableConfig.pagination.current);
 }

    actions = (record: any, appContext: any, contextUser: any,isUpdate: any, isDelete: any) => (
    <Popover
        content={
            <div className="center-div">
                  <Space size="small">
                      {
                          (isUpdate) &&
                          <Tooltip title="Edit Operator">
                              <Button data-id="opr-edit-button" type="default" icon={<EditOutlined />} onClick={(e) => { this.onAction(e, record, "edit-opr") }} />
                          </Tooltip>
                      }
                    {/* {!record.activeInScope && this.confirmStatus(record, "activate")}
                        {record.activeInScope && this.confirmStatus(record, "deactivate")}
                        */}
                        {/* {
                            <Tooltip title="View History">
                                <Button data-id="opr-history-button" type="default" icon={<FieldTimeOutlined />} onClick={(e) => { this.onAction(e, record, "history-opr") }} />
                            </Tooltip>
                        } */}
                        {(isDelete) && this.confirmDeletion(record)} 
                </Space>
            </div>
        }
        title={
            <div className="center-div">Actions</div>
        }
        trigger="click"
    >
        <Button data-id="opr-actions-button" type="default" icon={<EllipsisOutlined />} />
    </Popover>
);

confirmStatus = (record:any, action:string) => (
    <Popconfirm
        title={"Are you sure to " + (action === "activate" ? "activate" : "deactivate") + " this user?"}
        onCancel={closePopover}
        onConfirm={(e) => {this.onAction(e, record, action)}}
        okText="Yes"
        cancelText="No"
    >
        <div>
            {
                action === "activate" &&
                <Tooltip title="Activate User"> 
                    <Button data-id="opr-activate-button" type="default" icon={<UserAddOutlined />} />
                </Tooltip>
            }
            {
                action !== "activate" &&
                <Tooltip title="Deactivate User"> 
                    <Button data-id="opr-deactivate-button" type="default" icon={<UserDeleteOutlined />} />
                </Tooltip>
            }
        </div>
    </Popconfirm>
);
confirmDeletion = (record:any) => (
    <Popconfirm
        title={"Are you sure to delete this operator?"}
        onCancel={closePopover}
        onConfirm={(e) => {this.onAction(e, record, "delete")}}
        okText="Yes"
        cancelText="No"
    >
        <Tooltip title="Delete Operator">
            <Button data-id="opr-delete-button" type="default" icon={<DeleteOutlined />} />
        </Tooltip>
    </Popconfirm>
);
  onSearch = (searchText: string) => {
    let me = this;
  //  this.props.oprSetSearch((searchText || ""));
//      if((searchText || "")==="")
//      {
//        history.push("/operators");
//      }
//     setTimeout(function() {
//   //      me.props.getOprs(me, null, null, 1, searchText);
//     }, 100);
this.props.getOprs(this,this.getOprSuccess, null,  this.state.sortDirection, this.state.sortColumn?.toLowerCase(), this.state.page, searchText,null);
  }

   onSearchChange = (e: any) => {
       let value = e.target.value;
       this.props.oprSetSearch((value || ""));
    }

    onExport = (e: any) => {
        e.preventDefault();
     //   this.props.getOperatorsForExport(this, this.oprExportSuccess, null, this.props.oprList.searchText);
    }

    oprExportSuccess = (data: any) => {
        this.setState({ csvData: data, csvFileName: "operators.csv" })
        const me = this;
        setTimeout(function () {
            me.csvLink.link.click();
        }, 250);
    }
    beforeUpload = (file: any) => {
        const fileType = file.type === "text/csv" || file.type === "text/tsv" || file.type === "text/plain" || file.type === "application/vnd.ms-excel";
        if (!fileType) {
            notification.error({
                message: "File Processing",
                description: "CSV (comma separated values) and text file types are only allowed"
            });
        }

        return fileType;
    }

    handleChange = (info: any) => {
        if (info.file.status === "done") {
            message.success("File has been successfully processed");
        //    this.onPaginationChange(this.props.oprList.tableConfig.pagination.current);
        }
        else if (info.file.status === "error") {
            let formErrors: any = [];

            if (info.file.response.errors && (info.file.response.errors.length || 0) !== 0) {
                info.file.response.errors.forEach(function (record: any) {
                    if (record.type === "form") {
                        formErrors.push(record.message);
                    }
                });
            }
            if (formErrors.length > 0) {
                formErrors.forEach((error: string) => {
                    notification.error({
                        message: "File Processing",
                        description: error
                    });
                });
            }
            else {
                message.error(`${info.file.name} file upload failed.`);
            }
        }
    }; 

   render(){
    const { contextUser, appContext } = this.props.context;
    const { tableConfig, tableData } = this.props.oprList;
    const result = getPermissions(this.props.permissions.permissions.operator_support);
    if (!this.props.context.user.contextUserIsAdmin && !result[1]){
        history.push("/not-found")
    }

    let isCreate = true; //result[0];
    let isRead = true;//result[1];
    let isUpdate = true;//result[2];
    let isDelete = true;//result[3];

    let token=getToken();
    const tableColumns: any[] = [
        {
            title: "Operator ID",
            dataIndex: "operatorIdValue",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: this.state.sortColumn == 'operatoridvalue' ? this.state.sortDirection : false,
        },
        {
            title: "RFID",
            dataIndex: "accessCardValue",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: this.state.sortColumn == 'accesscardvalue' ? this.state.sortDirection : false,
        },
        {
            title: "PIN",
            dataIndex: "pin",
        },
        {
            title: "Asset Last accessed",
            dataIndex: "assetName",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: this.state.sortColumn == 'assetname' ? this.state.sortDirection : false,
        },
        {
            title: "Assets controlled",
            dataIndex: "mastertags",
            // responsive: ["lg"] as Breakpoint[],
            render: (record: any) => (
                <>
                  {record?.map((assetTag: any, key: number) => {
                      return (
                        <Tag key={key} style={{ margin: '2px' }}>
                            { assetTag.mac}
                        </Tag>
                    );
                  })}
                </>
              ),
           
        },
        {
            title: "Actions",
            key: "action",
            align: "center" as "center",
            render: (text: any, record: any) => (
               this.actions(record, appContext, contextUser, isUpdate, isDelete)
               //this.actions(record, null, null, isUpdate, isDelete)
             )
           
        }
       ]

       const sortOperatorsTable = (pagination: any, filters: any, sorter: any): void => {
        let sortColumn: string | undefined = undefined;
        let sortDirection: string | undefined = undefined;
        if (sorter && Object.keys(sorter).length > 0) {
            const { column, order } = sorter;
            sortDirection = order;
            sortColumn = column?.dataIndex.toLowerCase();//.replace(/\s/g, '');
            
        }
        // setPage(pagination.current);
        // setSortColumn(sortColumn || '');
        // setSortDirection(sortDirection || '');
        this.setState({sortColumn: sortColumn?.toLowerCase(), sortDirection: sortDirection, page: pagination.current});
        this.props.getOprs(this,this.getOprSuccess, null,  sortDirection, sortColumn?.toLowerCase(), pagination.current, this.props.oprList.searchText,null);
       
    }

    
       
    return (
        <div data-id="operators-container" className="layout-content">
         <Row gutter={8}>
                    <Col xs={24} lg={6}>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page">
                                    <BackNavigation />
                                </Tooltip>
                                Operators
                            </div>
                        </div>
                    </Col>
                   
                  
                    <Col xs={24}  lg={18} className="content-header-controls">
                  
                    <Space>
                        {/* {
                            (isCreate && isUpdate) &&
                            <Upload
                                {...uploadProps}
                                onChange={this.handleChange}
                            >
                                <Button>
                                    <ImportOutlined /> Import
                        </Button>
                            </Upload>
                        } */}
                        {/* {
                            (isRead) &&
                            <Tooltip title="Export Operators">
                                <Button data-id="opr-export-button" onClick={(e) => this.onExport(e)} icon={<ExportOutlined />}>
                                    <span>Export</span>
                                </Button>
                            </Tooltip>
                        } */}
                            {/* <CSVLink
                                data={this.state.csvData}
                                filename={this.state.csvFileName}
                                className="hidden"
                                ref={(r: any) => this.csvLink = r}
                                target="_blank"
                            /> */}
                      
                        {
                            (isRead) &&
                            <Search
                                data-id="operator-search-field"
                                key="search-field"
                                placeholder="Search by ID, Access Card, Asset, Tags"
                                enterButton="Search"
                                size="middle"
                                allowClear
                                onSearch={this.onSearch}
                                onChange={this.onSearchChange}
                                className="search-field"
                                value = {this.props.oprList.searchText}

                            />
                        }
                        {
                            (isCreate) &&
                            <Tooltip title="Create New Operator">
                                <Button data-id="opr-search-new-opr-button" type="primary"
                                    icon={<PlusOutlined />} onClick={(e) => this.onAction(e, {}, "add-opr")}>
                                    <span>Operator</span>
                                </Button>
                            </Tooltip>
                        }
                        </Space>
                    </Col>
                </Row>
            <div className="mt-15">
                {(isRead) &&
                    <Table
                        data-id="oprs-data"
                        {...tableConfig}
                        rowKey={(record) => record.id}
                        columns={tableColumns}
                        dataSource= {tableData}//{dataSource}
                        onChange={sortOperatorsTable}

                    />
                }
                <OprMaint/>
                </div>
        </div>
    );
   }
}

const mapStateToProps = (state:any) => {
    return {
        permissions: state.contextReducer.data,
        context: state.contextReducer.context,
        oprList: state.taylorOprReducers.oprState.oprList
    };
};

export default connect(
    mapStateToProps,
    actions
)(TaylorOperators);
