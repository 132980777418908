import { Button, Drawer, Space, Tabs } from "antd";
import React from "react";
import { connect } from "react-redux";
import * as actions from "../QuestionManagement/actions";
import { appConfigs } from "./../../../utils/configurations";
import QuestionBankMaintInfo from "./QuestionBankMaintInfo/QuestionBankMaintInfo";

const {TabPane}=Tabs;

interface Prop{
    questionBankList: any,
    getQbList: Function,
    qbMaintClose:Function,
    sortColumn: any,
    sortDirection: any,
    page:any
}

class QuestionBankMaint extends React.Component<Prop> {  
    componentDidUpdate(prevProps: Prop) {
        if (this.props.questionBankList.qbMaint.instance !== prevProps.questionBankList.qbMaint.instance) {
            this.closeCollapsed();
        }
    }    

onDrawerClose = () => {

    if (this.props.questionBankList.refreshOnUpdate) {
         //history.push("/operators");
        this.props.getQbList(this, null, null, this.props.sortDirection, this.props.sortColumn, this.props.page, null, null);
    }
       this.props.qbMaintClose(this);
};

closeCollapsed() {
    let active:any = document.querySelectorAll("[data-id=qb-maint-drawer] .ant-collapse-item-active");    
    if (active.length) {
        for (var i = 0; i < active.length; i++) {
            active[i]!.children[0].click();
        }
    }
}

render(){
    const { qbMaint } = this.props.questionBankList;

    return(
        <Drawer
        data-id="qb-maint-drawer"
        title={qbMaint.title}
        width={qbMaint.width}
        onClose={this.onDrawerClose}
        visible={qbMaint.visible}
        destroyOnClose={true}
        footer={
            <Space size="large" style={{ float: 'right' }}>
                <Button data-id="qb-maint-close-button" type="default" onClick={this.onDrawerClose}>
                    {appConfigs.settings.drawers.closeButtonText}
                </Button>
                {
                    qbMaint.activeTab === "1" &&
                    <Button data-id="qb-maint-submit-button" type="primary" form="qbMaintInfoForm" key="submit" htmlType="submit">
                        {appConfigs.settings.form.submitButtonText}
                    </Button>
                }
            </Space>
        }
    >
        <Tabs data-id="users-maint-info-tabs" activeKey={qbMaint.activeTab} size="large" type="card">
            <TabPane data-id="qb-maint-info-tab-button" tab={qbMaint.tabs[0].title} key="1">
                <QuestionBankMaintInfo  sortColumn={this.props.sortColumn} sortDirection={this.props.sortDirection} page={this.props.page} />
            </TabPane>
        </Tabs>
    </Drawer>
    );
}
}

const mapStateToProps = (state:any) => {
    return {
        questionBankList: state.questionsReducer.questionState.questionBankList
    };
};

export default connect(
    mapStateToProps,
    actions
)(QuestionBankMaint);