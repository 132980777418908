import React from "react";
import { Form, Row, Col, Input, Select, InputNumber, Checkbox } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { appConfigs } from "../../../utils/configurations";
import { successResponse, errorResponse, resetFormErrors } from "../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "../../../utils/apiHelpers/formHelpers";
import { SuccessAlert, ErrorAlert } from "../../../utils/Components/formAlerts";

import { connect } from "react-redux";
import * as actions from "../actions";
import history from '../../../utils/history';

const { Option } = Select;

interface Prop {
    signalCollectionMaintSubmit: Function,
    signalMaintOpen: Function,
    getSignalCollections: Function,
    signalCollections: any,
    signalCollectionMaint: any, 
    orgCmdPgnSpns: any
}

interface State {
    type: string;
    formError?: boolean;
    formErrorMessage?: string[];
    formSuccess?: boolean;
    formSuccessMessage?: string;
    formCommit?: boolean;  
}

class CollectionMaintInfo extends React.Component<Prop, State> {  
    
    state: State = {
        type: ""
    };

    formRef = React.createRef<FormInstance>();    
    drawerWidth:number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth - (window.innerWidth > 575 ? 100 : 0));

    getTemplateId() {
        const pathnameArr = history.location.pathname.split("/");
        return pathnameArr[pathnameArr.length - 1];
    }

    componentDidMount() {
        this.componentUpdate(this.props.signalCollectionMaint.record);
    }

    componentDidUpdate(prevProps: Prop, prevState: FormState) {
        if (this.props.signalCollectionMaint.instance !== prevProps.signalCollectionMaint.instance) {
            this.componentUpdate(this.props.signalCollectionMaint.record);
        }
    }

    componentUpdate(record:any) {
        formInit(this);     
        if (this.formRef.current != null) {
            this.formRef.current!.resetFields();   
            this.formRef.current!.setFieldsValue({
                signalCollectionId: record.signalCollectionId,
                templateId: Object.keys(record).length === 0 ? this.getTemplateId() : record.templateId,
                name: record.name,
                updateIntervalValue: Object.keys(record).length === 0 ? 1 : record.updateIntervalValue,
                updateIntervalUom: Object.keys(record).length === 0 ? "m" : record.updateIntervalUom,
                type: Object.keys(record).length === 0 ? "none" : record.type,
                allSignals: Object.keys(record).length === 0 ? true : record.allSignals
            });
            this.setState({ type: Object.keys(record).length === 0 ? "none" : record.type });
        }
    };


    onFinish = (values: any) => {
        resetFormErrors(this, this.formRef.current);
        this.props.signalCollectionMaintSubmit(this, this.onFinishSuccess, this.onFinishFailure, values);
    };

    onFinishSuccess = (editMode:string, record: any) => { 
        this.componentUpdate(record);
        successResponse(this, "Signal collection has been successfully " + (editMode === "edit" ? "updated" : "created and added to the template"));  

        this.props.getSignalCollections(this, null, null, this.getTemplateId(), this.props.signalCollections.tableConfig.pagination.current, this.props.signalCollections.searchText);  

        if (editMode === "insert" && (record.type === "none" || record.type === "Command")) {
            const me = this;
            setTimeout(function() {
                me.props.signalMaintOpen(me, me.drawerWidth, {}, record.type);      
            }, 250);  
            
        }
    }

    onFinishFailure = (error: any) => { 
        errorResponse(this, error);
    }
    
    onTypeChange = (type:string) => {
        let 
            defaultName = "",
            defaultIntervalValue = "1",
            defaultIntervalUom = "m";

        if (type === "GPS") {
            defaultName = "GPS";
            defaultIntervalValue = "1";
            defaultIntervalUom = "m";
        }
        else if (type === "IQAN Diagnostics") {
            defaultName = "IQAN Diagnostics";
            defaultIntervalValue = "5";
            defaultIntervalUom = "m";
        }
        else if (type === "Command") {
            defaultName = "";
            defaultIntervalValue = "0";
            defaultIntervalUom = "s";
        }

        const record  = this.props.signalCollectionMaint.record;
        if (Object.keys(record).length !== 0 && record.type === type) {
            defaultName = record.name;
            defaultIntervalValue = record.updateIntervalValue;
            defaultIntervalUom = record.updateIntervalUom;
        }

        this.formRef.current!.setFieldsValue({
            name: defaultName,
            updateIntervalValue: defaultIntervalValue,
            updateIntervalUom: defaultIntervalUom
        });

        this.setState({ type: type });
    };                            

    render() {
        
        return (

            <>
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="signal-collections-success-message" message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="signal-collections-success-message" message={this.state.formErrorMessage}/>}
                
                <Row gutter={8} style={{marginTop: 15}}>
                    <Col offset={1} span={23}>
                        <Form
                            id="signalCollectionMaintInfoForm"
                            ref={this.formRef}
                            layout="vertical"
                            onFinish={this.onFinish}
                        >

                            <Row gutter={8}>                                
                                <Col span={24}>
                                    {   
                                    !((this.state.type || "") === "GPS" || (this.state.type || "") === "IQAN Diagnostics")&&
                                    <Form.Item                                            
                                        name="allSignals"
                                        valuePropName="checked"
                                    >
                                        <Checkbox   
                                            style={{ width: '100%' }}
                                        >
                                            Wait for all signals in collection to send data to cloud<br/><span style={{color:'#0052CC',fontSize:'12px'}}>{"Minimum Requirements: TP1 2.4.1.5/HED 1.4.1 or later"}</span>
                                        </Checkbox>  
                                    </Form.Item>
                                    }     
                                </Col>                                                             
                            </Row>
                            <Row gutter={8}>
                                <Col xs={24} md={14}>
                                    <Form.Item
                                        label="Type"
                                        name="type"     
                                        rules={[
                                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                        ]}
                                    >
                                         
                                        <Select 
                                            onChange={this.onTypeChange}
                                        >
                                            <Option key="none" value="none">Generic</Option>
                                            <Option key="GPS" value="GPS">GPS (one per template)</Option>
                                            <Option key="IQAN Diagnostics" value="IQAN Diagnostics">IQAN Diagnostics (one per template)</Option>
                                            {(this.props.orgCmdPgnSpns == null || this.props.orgCmdPgnSpns !== '-') &&
                                            <Option key="Command" value="Command">Command</Option>}
                                        </Select> 
                                       
                                    </Form.Item>

                                </Col> 
                            </Row>  
                            <Row gutter={8}>
                                <Col span={24}>
                                    <Form.Item 
                                        name="signalCollectionId"
                                        style={{ display: 'none' }}
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>   

                                    <Form.Item 
                                        name="templateId"
                                        style={{ display: 'none' }}
                                    >
                                        <Input type="hidden" />
                                    </Form.Item>   

                                    <Form.Item
                                        label="Name"
                                        name="name"    
                                        rules={[
                                            { required: true, message: appConfigs.errors.fieldErrors.valueRequired }
                                        ]}
                                    >
                                        <Input 
                                            maxLength={255} 
                                            placeholder="Provide a unique, and easy to understand name for the signal collection"
                                            disabled={(this.state.type || "") === "GPS" || (this.state.type || "") === "IQAN Diagnostics" }  
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col xs={24} md={8}>
                                    <Form.Item
                                        label="Capture Interval"
                                        name="updateIntervalValue"     
                                        rules={(this.state.type || "") === "Command" ? 
                                            [{ required: false, message: appConfigs.errors.fieldErrors.valueRequired}] : 
                                            [{ required: true, message: appConfigs.errors.fieldErrors.valueRequired}]
                                        }
                                    >
                                        <InputNumber 
                                            min={(this.state.type || "") === "Command" ? 0: 1} 
                                            max={(this.state.type || "") === "Command" ? 0: 86400000} 
                                            style={{ width: '100%' }}  
                                            precision={0} 
                                            disabled={(this.state.type || "") === "IQAN Diagnostics" || this.state.type === "Command"}  
                                        />   
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item
                                        label="Units"
                                        name="updateIntervalUom"     
                                        rules={(this.state.type || "") === "Command" ? 
                                            [{ required: false, message: appConfigs.errors.fieldErrors.valueRequired }] :
                                            [{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]
                                        }
                                    >
                                        <Select 
                                            disabled={(this.state.type || "") === "IQAN Diagnostics" || this.state.type === "Command"}  
                                        >
                                            {/* <Option key="ms" value="ms">Milliseconds</Option> */}
                                            <Option key="s" value="s">Seconds</Option>
                                            <Option key="m" value="m">Minutes</Option>
                                            <Option key="h" value="h">Hours</Option>
                                        </Select>     
                                    </Form.Item>
                                </Col>
                            </Row>                                                                                              
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        signalCollections: state.templateDetailsReducers.templateDetailsState.tabs[0],
        signalCollectionMaint: state.templateDetailsReducers.templateDetailsState.tabs[0].signalCollectionMaint,
        orgCmdPgnSpns: state.templateDetailsReducers.templateDetailsState.templateRecord.orgCmdPgnSpns
    };
};

export default connect(
    mapStateToProps,
    actions
)(CollectionMaintInfo);