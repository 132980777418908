import React, { Component } from "react";
import { useOktaAuth } from '@okta/okta-react';
import { Layout, message} from "antd";
import Axios from "axios";
import "antd/dist/antd.css";
import "./App.css";

import { Router } from "react-router-dom";
import history from './utils/history';
import { SecureRoute } from '@okta/okta-react';

import { connect } from "react-redux";
import * as actions from "./actions";
import QuestionBank from "./pages/TaylorAccess/QuestionManagement/QuestionBank";
import TaylorOperators from "./pages/TaylorAccess/OperatorManagement/Operators";
import OperatorsHistory from "./pages/TaylorAccess/OperatorManagement/OperatorsHistory";

import { 
    setHeaderColor, loadingIndicatorStart, 
    loadingIndicatorEnd, clientStorageClear, loadPage, getLoginUrl,
    isSilentRequest,
    getToken,
    isContextUrl
} from "./utils/commonHelpers";

import TopHeader from "./layouts/TopHeader";
import LeftSider from "./layouts/LeftSider";

import FleetOverview from "./pages/FleetOverview2/FleetOverview";
import Dashboard from './pages/Dashboard';
import Dashboard2  from './pages/Dashboard2';
import Orgs from "./pages/Orgs/Orgs";
import Users from "./pages/Users/Users";
import Roles from "./pages/Roles/Roles";
//import Countries from "./pages/Countries/Countries";
import Alerts from "./pages/Alerts/Alerts";
import Assets from "./pages/Assets/Assets";
import UsersControllers from "./pages/Controllers/UsersControllers";
import Templates from "./pages/Templates/Templates";
import TemplateDetails from "./pages/TemplateDetails/TemplateDetails";
import NotFound404 from "./pages/NotFound404/NotFound404";
import Operators from "./pages/Operators/Oprs";
import Tutorials from "./pages/Help/Tutorials/Tutorials";
import Contracts from "./pages/Admin/Contracts/Contracts";
//import Controllers from "./pages/Admin/Controllers/Controllers";
import Applications from "./pages/Admin/Applications/Applications";
import AIOT from "./pages/Admin/AIOT";
import Controllers from "./pages/Iqanopen/Controllers/Devices";
import Softwares from "./pages/Iqanopen/Softwares/Softwares";
import UDS from "./pages/Iqanopen/UpdateDeviceSoftware/UDS";
import ReportSchedules from "./pages/ReportSchedules/Schedules";
import { appConfigs } from "./utils/configurations";
import FleetStatsOverview from "./pages/FleetOverview2/FleetStatsOverview";
import NotificationsOverview from "./pages/FleetOverview2/NotificationsOverview";
import OTA from "./pages/CSU/OTA";
import VideoTutorials from "./pages/Help/VideoTutorials/VideoTutorials";
import ReleaseNotes from "./pages/Help/ReleaseNotes/ReleaseNotes";
import UserGuide from "./pages/Help/UserGuide/UserGuide";
const { Content } = Layout;

interface Prop {
    contextGet: Function,
    supportModeInit: Function,
    supportMode: any,
    context: any,
    reportAccessGet: Function,
    userOrgAccessGet: Function,
    permissionsGet: Function,    
    maxCommandPermissionValueGet: Function,   
    getQuickStats: Function,    
    getAssetsWithDMs: Function,    
    permissions: any, 
    orgSelector: any, 
    maxCommandPermissionValue : any,
    stats: any, 
    notifications: any, 
}

function isSecure() {
    let 
        oktaStorage:string = window.localStorage.getItem("okta-token-storage") || "{}",
        oktaStorageJson:any = JSON.parse(oktaStorage);            

    return "idToken" in oktaStorageJson && "value" in oktaStorageJson.idToken && (oktaStorageJson.idToken.value || "") !== "";
}

function interceptorsSetup(props: any, supportMode: any, permissions: any) {
    
    Axios.interceptors.request.use(
        function(config) {  
            let 
                oktaStorage:string = window.localStorage.getItem("okta-token-storage") || "{}",
                oktaStorageJson:any = JSON.parse(oktaStorage),
                supportModeString:string = window.sessionStorage.getItem("support-mode") || "{}",
                supportModeJson:any = JSON.parse(supportModeString);
                
            if (!isSilentRequest(config.url || "")) {
                loadingIndicatorStart(); 
            }
            let token=getToken();
            //config.headers.Authorization = `Bearer ${oktaStorageJson.idToken.value}`;  
             if(token === null)
             {
                loadPage(getLoginUrl());
             }
            config.headers.Authorization = `Bearer ${token.value||token.idToken}`;            
            if (supportModeString !== "{}") {
                config.headers["X-support-org-id"] = supportModeJson.orgId;
            }

            return config;
        }, 
        function(error) {
            loadingIndicatorEnd();
            return Promise.reject(error);
        }
    );
    Axios.interceptors.response.use(
        function (response) {
            

            if (isContextUrl(response.config.url || "")) {
                if (!response.data.data.context.user.active) {
                    clientStorageClear();
                    loadingIndicatorStart();
                    message.loading("Logging out Deactivated User...", 0);
                    localStorage.removeItem("pathname");
                    window.localStorage.setItem("logoutInProgress", "true");
                    // authService.logout(window.location.origin + "/login");
                }
            }

            if (!isSilentRequest(response.config.url || "")) {
                loadingIndicatorEnd();
            }
            return response;
        }, 
        function (error) {
            loadingIndicatorEnd();
            return Promise.reject(error);
        }
    );
}

class App extends Component<Prop, {}> {

    menuCounter: number = 0;
    
    componentDidMount () {  
        if (isSecure()) {
            interceptorsSetup(this.props.context, this.props.supportMode, this.props.permissions);            
            this.props.contextGet(this, this.contextGetSuccess, this.contextGetFailure);
            this.props.permissionsGet(this, this.permissionsGetSuccess, this.contextGetFailure);
            this.props.maxCommandPermissionValueGet(this, this.maxCommandPermissionValueGetSuccess, this.contextGetFailure);
            this.props.getQuickStats(this, this.statsGetSuccess, this.contextGetFailure);
            this.props.getAssetsWithDMs(this.contextGetFailure);
            this.props.supportModeInit(this, null);                               
        }
        else {        
            loadPage(getLoginUrl());    
        }
    }

    contextGetSuccess = (data:any) => {
        setTimeout(function () {
            setHeaderColor(data.orgSettings.headerColor || "#000000");
        }, 250);            
        
        if (data.appContext.orgId) {
            this.props.reportAccessGet(this, null, null, data.appContext.orgId);
            this.props.userOrgAccessGet(this, null, null, data.appContext.orgId, data.appContext.userId);   
            this.props.maxCommandPermissionValueGet(this, null, null, data.appContext.orgId, data.appContext.userId);           
        }         
    }

    contextGetFailure = (error:any) => {
        const errorCode:number = !error.response ? 999 : (error.response.status || 999);
        
        if (errorCode === 400 || errorCode === 500) {
            const loginUrl = getLoginUrl();
            const loginError = {
                "stop" : true, 
                "errorCode": errorCode, 
                "errorObject": error.response.data.errors
            };
            
            loadingIndicatorStart();
            clientStorageClear();
            window.sessionStorage.setItem("loginError", JSON.stringify(loginError));           
            loadPage(loginUrl);              
        }
        else {
            if (errorCode !== 401) {
                message.error("Unable to get app user context");
            }
        }
    }

    permissionsGetSuccess = () => {
        setTimeout(() =>{},250); 
    }


    maxCommandPermissionValueGetSuccess = () => {
        setTimeout(() =>{},250); 
    }

    statsGetSuccess = () => {
        setTimeout(() =>{},250); 
    }

    rolesGetSuccess = () => {
        setTimeout(() =>{},250); 
    }
    
      
    render() {
        
            let contractsOrgsList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'contracts')[0]?.orgsList;
            let applicationsOrgsList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'applications')[0]?.orgsList;
            let aiotOrgsList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'aiot')[0]?.orgsList;
            let iqanopenOrgsList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'iqanopen')[0]?.orgsList;
            let controllersOrgsList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'controllers')[0]?.orgsList;
            let contractsUsersList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'contracts')[0]?.usersList;
            let applicationsUsersList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'applications')[0]?.usersList;
            let aiotUsersList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'aiot')[0]?.usersList;
            let controllersUsersList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'controllers')[0]?.usersList;
            let iqanopenUsersList= this.props.context?.systemSettings?.filter((sysSetting: any) => sysSetting.settingName === 'iqanopen')[0]?.usersList;
        return (
            <Router history={history}>
                <Layout>
                    {
                        (this.props.context.initialized || false) &&
                        <LeftSider />
                    }
                    <Layout className="site-layout" style={{ height: "100vh" }}>
                        {
                            (this.props.context.initialized || false) &&
                            <TopHeader />
                        }
                        <Content>

                            {
                                (this.props.stats.initialized && this.props.orgSelector.orgSelection.length > 0) &&
                                <FleetStatsOverview />
                            }

                            {
                                (this.props.stats.initialized && this.props.orgSelector.orgSelection.length > 0) &&
                                <NotificationsOverview />
                            }
                                
                            <SecureRoute exact path="/map" render={() => {
                                //---------------------------------------------------------------------------------------------------
                                // In general, push the component only when /context comes back, not before. Otherwise, the component
                                // will be pushed twice (first time for blank context and second time for the updated context) 
                                // resulting in fireing twice component level listeners.
                                //---------------------------------------------------------------------------------------------------
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <FleetOverview mapMode={this.props.context.orgSettings.fleetMapDefaultView} />;
                                }
                            }} />
                            
                            <SecureRoute exact path="/organizations" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Orgs />;
                                }
                            }} />

                            <SecureRoute exact path="/users" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Users />;
                                }
                            }} />
                             <SecureRoute exact path="/roles" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Roles />;
                                }
                            }} />
                            {/*
                                OPS 1149
                                
                                <SecureRoute exact path="/countries" render={() => {
                                    if ((this.props.context.initialized || false)) {
                                        this.menuCounter++;
                                        return <Countries />;
                                    }
                                }} />
                            */}
                            <SecureRoute exact path="/alerts" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Alerts />;
                                }

                            }} /> 

                            <SecureRoute exact path="/alerts/:mode" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Alerts />;
                                }
                            }} />                                        

                            <SecureRoute exact path="/assets" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Assets />;
                                }
                            }} />

                            <SecureRoute exact path="/ota" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <OTA />;
                                }
                            }} />


                            <SecureRoute exact path="/controllers" render={() => {
                                if (
                                        (this.props.context.initialized || false) && 
                                        this.props.context.user.contextUserIsAdmin && 
                                        (controllersOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                        && 
                                        
                                (controllersUsersList == null
                                    ? false
                                    : controllersUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId)
                                )
                                    ){
                                    this.menuCounter++;
                                    return <UsersControllers />;
                                }
                            }} />

                            <SecureRoute exact path="/assets/:mastertag/details" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Assets />;
                                }
                            }} />
                            { !appConfigs.app.enableDashboard2 ? 
                                (<SecureRoute exact path="/assets/:assetId" render={() => {
                                    if ((this.props.context.initialized || false)) {
                                        this.menuCounter++;
                                        return <Dashboard />;
                                    }
                                }} />) :
                                (
                                    <SecureRoute exact path="/assets/:assetId" render={() => {
                                        if ((this.props.context.initialized || false)) {
                                            this.menuCounter++;
                                            return <Dashboard2 />;
                                        }
                                    }} />

                                ) 
                            }

                            <SecureRoute exact path="/templates" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Templates />;
                                }
                            }} />

                            <SecureRoute exact path="/template/details/:templateName/:templateId" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <TemplateDetails />;
                                }
                            }} />

                            <SecureRoute exact path="/reportschedules" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <ReportSchedules />;
                                }
                            }} />

                           <SecureRoute exact path="/operators" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Operators />;
                                }
                            }} />
                            <SecureRoute exact path="/operators/:operatorId" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Operators />;
                                }
                            }} />
                            <SecureRoute exact path="/operators/:operatorId/:operatorsHistoryId" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Operators />;
                                }
                            }} />
                            <SecureRoute exact path="/tutorials" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <Tutorials />;
                                }
                            }} />
                            <SecureRoute exact path="/videotutorials" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <VideoTutorials />;
                                }
                            }} />
                             <SecureRoute exact path="/questionmanagement" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <QuestionBank />;
                                }
                            }} />
                            <SecureRoute exact path="/operatorsmanagement" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <TaylorOperators />;
                                }
                            }} />
                            <SecureRoute exact path="/operatorshistory" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <OperatorsHistory />;
                                }
                            }} />
                             <SecureRoute exact path="/releasenotes" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <ReleaseNotes />;
                                }
                            }} />
                             <SecureRoute exact path="/userguide" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <UserGuide />;
                                }
                            }} />
                            <SecureRoute exact path="/admin/contracts" render={() => {
                                if ((this.props.context.initialized || false) && this.props.context.user.contextUserIsAdmin && ((contractsOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId)) && 
                                    (
                                        (contractsUsersList == null
                                            ? false
                                            : contractsUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId))
                                ))) {
                                    this.menuCounter++;
                                    return <Contracts />;
                                }
                            }} />

                            <SecureRoute exact path="/admin/controllers" render={() => {
                                if ((this.props.context.initialized || false) && this.props.context.user.contextUserIsAdmin && (controllersOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                && 
                                (controllersUsersList == null
                                    ? false
                                    : controllersUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId))
                                ) 
                                {
                                    this.menuCounter++;
                                    return <Controllers />;
                                }
                            }} />


                            <SecureRoute exact path="/admin/applications" render={() => {
                                if ((this.props.context.initialized || false) && this.props.context.user.contextUserIsAdmin && (applicationsOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                && (applicationsUsersList == null
                                    ? false
                                    : applicationsUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId))
                                )
                                {
                                    this.menuCounter++;
                                    return <Applications />;
                                }
                            }} />



                            <SecureRoute exact path="/admin/aiot" render={() => {
                                if ((this.props.context.initialized || false)
                                    && (
                                        (aiotOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                    && (
                                        (aiotUsersList == null
                                            ? false
                                            : aiotUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId))
                                )
                                )) {
                                    this.menuCounter++;
                                    return <AIOT />;
                                }
                            }} /> 
                            <SecureRoute exact path="/Iqanopen/controllers" render={() => {
                                 if ((this.props.context.initialized || false)
                                    && (
                                        (iqanopenOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                    && (
                                        (iqanopenUsersList == null
                                            ? false
                                            : iqanopenUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId))
                                )
                                )) {
                                    this.menuCounter++;
                                    return <Controllers />;
                                }
                            }} />
                             <SecureRoute exact path="/Iqanopen/softwares" render={() => {
                                if ((this.props.context.initialized || false)
                                    && (
                                        (iqanopenOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                    && (
                                        (iqanopenUsersList == null
                                            ? false
                                            : iqanopenUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId))
                                )
                                )) {
                                    this.menuCounter++;
                                    return <Softwares />;
                                }
                            }} />
                             <SecureRoute exact path="/Iqanopen/UpdateDeviceSoftware" render={() => {
                                 if ((this.props.context.initialized || false)
                                    && (
                                        (iqanopenOrgsList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.orgId))
                                    && (
                                        (iqanopenUsersList == null
                                            ? false
                                            : iqanopenUsersList?.replaceAll('"', "").slice(1, -1).split(",").includes(this.props.context.appContext.userId))
                                )
                                )) {
                                    this.menuCounter++;
                                    return <UDS />;
                                }
                            }} />

                            <SecureRoute exact path="/not-found" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    this.menuCounter++;
                                    return <NotFound404 />;
                                }
                            }} />

                            <SecureRoute path="*" render={() => {
                                if ((this.props.context.initialized || false)) {
                                    if ((this.menuCounter || 0) === 0) {
                                        return <NotFound404 />;
                                    }
                                }
                            }} />
                                
                        </Content>
                    </Layout>
                </Layout>
            </Router>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        supportMode: state.supportModeReducer,        
        context: state.contextReducer.context,
        stats: state.contextReducer.stats,
        notifications: state.contextReducer.notifications,
        report: state.reportReducer,
        userOrgAccess: state.userOrgAccessReducer,
        permissions: state.contextReducer.data,
        orgSelector: state.orgSelectorReducers.orgSelectorState.orgSelector,
        maxCommandPermissionValue : state.contextReducer.data,
    };
};

export default connect(
    mapStateToProps,
    actions
)(App);