import { ExportOutlined } from "@ant-design/icons";
import { Button, Col, Row, Select, Space, Table, Tooltip } from 'antd';
import Search from "antd/lib/input/Search";
import * as FileSaver from 'file-saver';
import React from "react";
import { connect } from "react-redux";
import * as XLSX from 'xlsx';
import BackNavigation from "../../../utils/Components/BackNavigation";
import * as actions from "./actions";

const { Option } = Select;
interface Prop{
    oprList:any,
    context: any,
    oprHistory: any,
    getOperatorsHistory: Function,
    oprHistorySetSearch: Function
}

interface State {
    csvData: any,
    csvFileName: string,
    sortColumn: any,
    sortDirection: any,
    page: number
}
class OperatorsHistory extends React.Component<Prop,State> {

    state: State = {
        csvData: [],
        csvFileName: "",
        sortColumn: "accessDate",
        sortDirection: "ascend",
        page: 1
    };

    csvLink: any = React.createRef<{ link: HTMLAnchorElement }>();
    componentDidMount() {
        this.componentUpdate(this.props.oprList.oprMaint.record);
    }
    componentDidUpdate(prevProps: Prop) {
         if (this.props.oprList.oprMaint.instance !== prevProps.oprList.oprMaint.instance) {
             this.componentUpdate(this.props.oprList.oprMaint.record);
        }
    }

    onSearch = (searchText: string) => {

        this.props.getOperatorsHistory(this,null, null,  this.state.sortDirection, this.state.sortColumn?.toLowerCase(), this.state.page, searchText,null);
    }

    onSearchChange = (e: any) => {
       let value = e.target.value;
       this.props.oprHistorySetSearch((value || ""));
    }

    componentUpdate(record:any) {
        this.props.getOperatorsHistory(this,null, null,  this.state.sortDirection, this.state.sortColumn?.toLowerCase(), this.state.page, this.props.oprHistory.searchText,null);
        // formInit(this);
        // let orgId = (record.orgId || "") === "" ? this.props.context.appContext.orgId:record.orgId;
        // this.props.operatorOrgUsersGet(this, null, null,orgId);
        // this.props.operatorOrgAssetsGet(this, null, null, 'all', orgId);
        // //this.props.operatorOrgAccessTypesGet(this, null, null, 'all', orgId);
        
        // if (this.formRef.current != null) {
        //     this.formRef.current!.resetFields(); 
        //     this.formRef.current!.setFieldsValue({
        //         orgId: (record.orgId || "") === "" ? (this.props.context.appContext.roleName === "admin" ? this.props.context.appContext.orgId: null) : record.orgId,    
        //         operatorIdValue:record.operatorIdValue,
        //         accessType:record.accessType,
        //         accessCardValue:record.accessCardValue,
        //         userId:record.userId,
        //         assetName:record.assetName,
        //         accessCardId:record.accessCardId,
        //         pinValue: record.pinValue,
        //         mastertags: record.mastertags?.map((assetTag:any) => {return {value: assetTag.mastertag, label: assetTag.mastertag, key: assetTag.mastertag} }),
        //         id:record.id
              
        //     });
        // }
    }
   
    // onFinish = (values: any) => {
    //     resetFormErrors(this, this.formRef.current);
    //     this.props.oprMaint(this, this.onFinishSuccess, this.onFinishFailure, values, this.props.oprList.oprMaint.record.operatorsHistoryId, this.state.isOverrideRFID);
    // };

    // onFinishSuccess = (record: any) => { 
    //   //  this.props.oprsListRefreshOnUpdate(this);
        
    //     if (record != null && record.operatorIdValue != null && 
    //         Object.keys(record.operatorIdValue) != null && Object.keys(record.operatorIdValue).length !== 0) {
    //         this.componentUpdate(record);
    //     }
    //     this.setState({ isOverrideRFID: false, isOveridePanelDisplay: false, overrideErrorMessage: "" });
    //     successResponse(this, "Operator has been successfully processed");   
    // }
    // onFinishFailure = (error: any) => { 
    //     if (error.response.data.errors[0].reference === "override") {
    //         const errMsg = error.response.data.errors[0].message;
    //         this.setState({ isOveridePanelDisplay: true, overrideErrorMessage: errMsg });
    //     }
    //     else {
    //         this.setState({ isOverrideRFID: false, isOveridePanelDisplay: false, overrideErrorMessage: "" });
    //         errorResponse(this, error);
    //     }
    // }

    // onOverrideSubmit = (e: any) => {
    //     this.setState({ isOverrideRFID: true });
    //     this.formRef.current?.submit();
    // }
    // cancelOverride = (e: any) => {
    //     this.setState({ isOverrideRFID: false, isOveridePanelDisplay: false, overrideErrorMessage: "" });
    // }

    sortOperatorsHistoryTable = (pagination: any, filters: any, sorter: any): void => {
        let sortColumn: string | undefined = undefined;
        let sortDirection: string | undefined = undefined;
        if (sorter && Object.keys(sorter).length > 0) {
            const { column, order } = sorter;
            sortDirection = order;
            sortColumn = column?.dataIndex.toLowerCase();//.replace(/\s/g, ''
            
        }
        // setPage(pagination.current);
        // setSortColumn(sortColumn || '');
        // setSortDirection(sortDirection || '');
        this.setState({sortColumn: sortColumn?.toLowerCase(), sortDirection: sortDirection, page: pagination.current});
        this.props.getOperatorsHistory(this,null, null,  sortDirection, sortColumn?.toLowerCase(), pagination.current, this.props.oprHistory.searchText,null);
    }
    
    // onAccessTypeChange = (value:any) => {
    //     this.setState({ accessType: value });
    //     if (value.toLowerCase() !== 'rfid') {
    //         this.setState({ rfidValue: ""})
    //      //   this.props.rfidSetValue(this, "");
    //     }
    // };

    // onInputRfidChange = (value:any) => {
    //     this.setState({ rfidValue: value || "" == ""? "" : value.target.value})
    //    // this.props.rfidSetValue(this, value || "" == ""? "" : value.target.value);
    // };

    render(){
        //  const { orgUsers, orgAssets, orgAccessTypes, rfidValue } = this.props.oprList.oprMaint.tabs[0];
        //  const {operatorsHistoryId, mastertags, accessType} = this.props.oprList.oprMaint.record;
         const dataSource1 = this.props.oprList.operatorHistoryTableData;
         // let csvLink: any = React.createRef<CSVLink>();
          const tableColumns: any[] = [
            {
                title: "Date",
                dataIndex: "accessDate",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.sortColumn == 'accessdate' ? this.state.sortDirection : false,
                render: (text: any, record: any) => (
                    <>
                    {
                        <span>{new Date(record.accessDate).toLocaleString()}</span>
                    }
                    </>
                )
            },
            {
                title: "Asset",
                dataIndex: "assetName",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.sortColumn == 'assetname' ? this.state.sortDirection : false,
                
            },
            {
                title: "Operator",
                dataIndex: "operatorIdValue",
                sortDirections: ['ascend', 'descend', 'ascend'],
                sorter: true,
                sortOrder: this.state.sortColumn == 'operatoridvalue' ? this.state.sortDirection : false
            },
            {
                title: "Access Type",
                dataIndex: "accessType",
            },
            {
                title: "Question Bank Name",
                dataIndex: "questionBankName",
            },
            {
                title: "Result Question Bank",
                dataIndex: "result",
            },
            {
                title: "Export Question Bank",
                dataIndex: "Response",
                render: (text: any, record: any) => (
                    <>
                    {
                <><Tooltip title="Export QuestionBank">
                                <Button data-id="qb-export-button" onClick={(e) => this.onExport(e, record)} icon={<ExportOutlined />}>
                                    <span>Export</span>
                                </Button>
                            </Tooltip>
                                {/* <CSVLink
                                    data={this.state.csvData}
                                    filename={this.state.csvFileName}
                                    onClick={(e: any) => this.onExport(e, record)}
                                    // className="hidden"
                                    ref={(r: any) => this.csvLink = r}
                                    target="_blank" >Export1</CSVLink> */}
                                    </>
                            }
                            </>
                        )
            },
           ]
        
        return(
            <div data-id="operators-history-container" className="layout-content">
         <Row gutter={8}>
                    <Col xs={24} lg={6}>
                        <div className="content-header page-title">
                            <div style={{ display: "flex" }}>
                                <Tooltip title="Go To Previous Page">
                                    <BackNavigation />
                                </Tooltip>
                                Operator History
                            </div>
                        </div>
                    </Col>
                   
                  
                    <Col xs={24}  lg={18} className="content-header-controls">
                  
                    <Space size="middle">  
                        
                        {
                           // (isRead) &&
                            <Search
                                data-id="operator-search-field"
                                key="search-field"
                                placeholder="Search by Operator ID, Asset"
                                enterButton="Search"
                                size="middle"
                                allowClear
                                onSearch={this.onSearch}
                                onChange={this.onSearchChange}
                                className="search-field"
                                value={this.props.oprHistory.searchText}

                            />
                        }
                        </Space>
                    </Col>
                </Row>
            <div className="mt-15">

            <Table
                        data-id="oprs-history"
                      // {...dataSource}
                        rowKey={(record:any) => record.recordNo }
                        columns={tableColumns}
                        dataSource={dataSource1}
                        onChange={this.sortOperatorsHistoryTable}

                    />
                    </div>
                    </div>
        );
    }
    onExport(e: any, record: any): void {
        e.preventDefault();
        this.qandaExportSuccess(this,record);
    }
    qandaExportSuccess(me:any,operatorHistory: any) {
        let result=[];
        for (const [key, value] of Object.entries(JSON.parse(operatorHistory.answers))) { 
            result.push([`${key}`, `${value}`]); 
        }
        this.setState({ csvData: result, csvFileName: operatorHistory.id.toString()+".csv" });
        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';
        let Heading = [['Question', 'Answer']];

        const ws = XLSX.utils.json_to_sheet(result);
        XLSX.utils.sheet_add_aoa(ws, Heading);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, operatorHistory.id.toString() + fileExtension);
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        oprList: state.taylorOprReducers.oprState.oprList,
        oprHistory: state.taylorOprReducers.oprState.oprHistory
    };
};

export default connect(
    mapStateToProps,
    actions
)(OperatorsHistory);