import {
    CheckCircleOutlined,
    CheckSquareFilled,
    CloseSquareFilled,
    InfoOutlined,
    LikeOutlined,
    Loading3QuartersOutlined,
    PushpinOutlined,
    StopFilled
} from "@ant-design/icons";
import { Button, Col, Radio, Row, Space, Table, Tooltip, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import offlineIcon from "../../assets/images/map-status/offline.png";
import onlineIcon from "../../assets/images/map-status/online.png";
import { getPermissions } from "../../utils/commonHelpers";
import { appConfigs } from "../../utils/configurations";
import * as actions from "./actions/csu";
import CalibrationMaint from "./CalibrationMaint";
import "./csu.css";


interface Props {    
    context: any;
    permissions: any;
    tabData: any;
    search: string;
    isSearch: boolean;
    csus: any;
    getCalibrations: Function;
    csuMaintOpen: Function;
    csuSetPaginationForAll: Function,
    processAction: Function;
    resetSearch: Function;
  }
const CSU: React.FC<Props> = (props) => {
   // const [calibrations, setCalibrations] = useState([]);
   // const { contextUser, appContext } = props.context;
    const [category, setCategory] = useState("Approval Required");
    const [disableAction, setDisableAction] = useState(false);
    const result = getPermissions(props.permissions.permissions.asset_widgets);
    const [sortColumn, setSortColumn] = useState("assetname");
    const [page, setPage] = useState(1);
    const [sortDirection, setSortDirection] = useState("ascend");
    const [open, setOpen] = useState(false);
    const { tableConfig, tableData, search } = props.csus;
    useEffect(() => {
        if (props.tabData === "1") {
           // props.getCalibrations(getOTAOnSuccess,getOTAOnError,null, null, null, '', null, 1);
           props.csuSetPaginationForAll(onPaginationChange);
           props.getCalibrations(getOTAOnSuccess,getOTAOnError,null, null, null, props.search, category,0, page);
        }
        // return () => {
        //     setCalibrations([]);
        // }
    }, [props.isSearch, page]);

    useEffect(() => {
        if (props.tabData === "1") {
           // props.getCalibrations(getOTAOnSuccess,getOTAOnError,null, null, null, '', null, 1);
           props.csuSetPaginationForAll(onPaginationChange);
           props.getCalibrations(getOTAOnSuccess,getOTAOnError,null, null, null, props.search, category,0, 1);
        }
        // return () => {
        //     setCalibrations([]);
        // }
    }, [props.search, props.tabData, category]);


    const getOTAOnSuccess = (ota:any)=>{
            props.resetSearch();
            ota.map((data:any) => {
                // console.log("CALIBRATION INFO: "+ JSON.stringify(data));
            });
         //   setCalibrations(ota);
    }

    const getOTAOnError = (error:any) =>  {
        console.log("Error: "+ JSON.stringify(error));
    }

    const initiateAction=(e:any,record:any, operationType: any) =>{
        console.log("e: "+ e);
        console.log("operationType: "+ operationType);
        console.log("record: "+ JSON.stringify(record));
        let startStep: any = null;
        if(operationType.toString().toLowerCase() === "approve update") {
            startStep = 1;
            if(record.approved === 1) {
                if(!record.storageLocation || record.storageLocation.toString()==="" ||record.storageLocation === null)
                {
                    startStep = 2;
                }
                else
                {
                    if(!record.csuJobId || record.csuJobId === null){
                        startStep = 3;
                    }
                }

            }
        }
        setDisableAction(true);
        props.processAction(record, operationType.toString().toLowerCase(),actionOnSuccess,actionOnError, startStep);
    }

    const actionOnSuccess = (data:any, operationType:any) => {
        //console.log("DATA: "+ data);
        //console.log("JSON STRINGIFY DATA: "+ JSON.stringify(data));
        if(operationType.toString().toLowerCase()==="cancel update")
        {
            message.success("OTA Cancellation initiated successfully.");
        }
        else
        {
            message.success("OTA Update initiated successfully.");
        }
        setDisableAction(false);
        props.getCalibrations(null,null,null, null, null, search, category,0, page);
    }

    const actionOnError = (error:any) => {
        // console.log("Error: "+ JSON.stringify(error));
        message.error(error?.response?.data?.errors?.length > 0 ?
            "Error: "+error?.response?.data?.errors[0].message :
             "Failed to initiate the OTA process.")
        setDisableAction(false);
    }

    const calibrationMaintOpen = (e:any, record:any) => {
        e.preventDefault();
        calibrationMaintOpenDo(record);
    };
    const drawerWidth: number = Math.min(window.innerWidth, appConfigs.settings.drawers.defaultWidth);
    const calibrationMaintOpenDo = (record:any) => {
        const title =  "Asset :: " + (record.nickname || record.mastertag) + " :: " + record.status.charAt(0).toUpperCase() + record.status.slice(1);
        props.csuMaintOpen(title, drawerWidth, record);
    }

    const actionType = (type: any) => {
        if(type == 1) {
            return "Approve Update";
        }
        else if(type == 2) {
            return "Deny Update";
        }
        else if(type == 3) {
            return "Retry Update";
        }
        else if(type == 4) {
            return "Cancel Update";
        }
        else if(type == 5) {
            return "Restore";
        }
        else if(type == 6) {
            return "Approve Restore";
        }
        else if(type == 7) {
            return "Deny Restore";
        }
        else if(type == 8) {
            return "Retry Restore";
        }
        else if(type == 9) {
            return "Cancel Restore";
        }
    }
    console.log("Table Config ALL: "+ JSON.stringify(tableConfig));

    console.log("TABLE DATA ALL");
    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++");
    console.log("HOSTORY DATA ALL: "+ tableData);
    // console.log("+++++++++++++++++++++++++++++++++++++++++++++++++");

    const onPaginationChange = (page: number) => {
        props.getCalibrations(getOTAOnSuccess,getOTAOnError,null, null, null, search, category,0, page);
    };

    const onStatusChange = (e: any) => {
        e.preventDefault();
        setPage(1);
        setCategory(e.target.value || 'Approval Required');
        let status = e.target.value;
        props.getCalibrations(getOTAOnSuccess,getOTAOnError,null, null, null, search, status, 0, 1);
    };
    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++");
    console.log("Category: "+ category);
    console.log("+++++++++++++++++++++++++++++++++++++++++++++++++");
    
    let tableColumns = [
        {
            title: "Asset",
            column_name:"assetName",
            dataIndex: "assetName",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'assetname' ? sortDirection : false,
        },
        {
            title: "Status",
            column_name:"connectionStatus",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'connectionstatus' ? sortDirection : false,
            render: (text: any, record: any) => (
                            <>
                                 {
                                    record.connectionStatus === "ONLINE" && //record.status !== "inactive" && record.status !== "whitelisted" &&
                                    <Tooltip title="Online">
                                        <span className="asset-status-green alarm-info-icon pr-5" ><img src={onlineIcon} alt="" /></span>
                                    </Tooltip>
                                }
                                {/* {
                                    record.connectionStatus === "ONLINE" && record.status !== "active" &&
                                    <Tooltip title="Offline">
                                        <span className="asset-status-red alarm-info-icon pr-5" ><img src={offlineIcon} alt="" /></span>
                                    </Tooltip>
                                } */}
                                {
                                    record.connectionStatus === "OFFLINE" &&
                                    <Tooltip title="Offline">
                                        <span className="asset-status-red alarm-info-icon pr-5" ><img src={offlineIcon} alt="" /></span>
                                    </Tooltip>
                                }
                            </>
                        )
        },
        {
            title: <Tooltip mouseEnterDelay={0.5} data-title="Status Since" title="Represents most recent device's connect/disconnect timestamp"><span>Status Since</span></Tooltip>,
            column_name: "Status Since",
           // responsive: ["xl"] as Breakpoint[],
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            //sortOrder: this.state.assetTableFilters.sortColumn == 'statussince' ? this.state.assetTableFilters.sortDirection : false,
            align: "center" as "center",
            render: (text: any, record: any) => (
                <>
                {
                        record.connection_status === 'whitelisted' ?
                        <>{appConfigs.app.assets.offlineHavingNoDateText}</>
                        :record.disconnetedSince ?
                        <span>{new Date(record.disconnetedSince).toLocaleString()}</span>
                        : <>{appConfigs.app.assets.offlineHavingNoDateText}</>
                        //: <>{appConfigs.app.assets.onlineText}</>
                }
                </>
            )
        },
        {
            title: "Mastertag",
            column_name:"mastertag",
            dataIndex: "mastertag",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'mastertag' ? sortDirection : false,
        },
        {
            title: "ESN",
            column_name:"esn",
            dataIndex: "esn",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'esn' ? sortDirection : false,
        },
        {
            title: "Current Version",
            column_name:"currentVersion",
            dataIndex: "currentVersion",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'currentversion' ? sortDirection : false,
        },
        {
            title: "New Version",
            column_name:"newVersion",
           // dataIndex: "newVersion",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'newversion' ? sortDirection : false,
        //     <Badge.Ribbon text="Hippies">
        //     <Card title="Pushes open the window" size="small">
        //       and raises the spyglass.
        //     </Card>
        //   </Badge.Ribbon>
        render: (text: any, record: any) => (
            <>
            {
                // <div> <span className="ribbon">{(record?.persistMode)? ((record.persistMode===2)?"TEST": "CMNS"):null}</span>
                //     <span>{record.newVersion}</span>
                // </div>
                <div> {(record?.persistMode)? ((record.persistMode===2)?<span className="ribbon">TEST</span>:""):""}
                    <span>{record.newVersion}</span>
                </div>
            }
            </>
        )
        },
        // {
        //     title: "Available Time",
        //     column_name:"availableTime",
        //     //dataIndex: "availableTime",
        //     sortDirections: ['ascend', 'descend', 'ascend'],
        //     sorter: true,
        //     sortOrder: sortColumn == 'availabletime' ? sortDirection : false,
        //     render: (text: any, record: any) => (
        //         <>                    
        //         {      
        //             <span>{moment(record.availableTime).format('MM/DD/YYYY, HH:mm:ss A').toLocaleString()}</span> 
        //         }
        //         </>
        //     )
        // },
        {
            title: "OTA Status",
            column_name:"otaStatus",
            dataIndex: "otaStatus",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'otastatus' ? sortDirection : false,
        },
        {
            title: "Status At",
            column_name:"statusAt",
           // dataIndex: "statusAt",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'statusat' ? sortDirection : false,
            render: (text: any, record: any) => (
                <>                    
                {      
                    <span>{moment(record.statusAt).format('MM/DD/YYYY, h:mm:ss A').toLocaleString()}</span>                       
                }
                </>
            )
        },
        {
            title: "Attempts",
            column_name:"attempts",
            dataIndex: "attempts",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'attempts' ? sortDirection : false,
        },
        {
            title: "Requests",
            column_name:"reqs",
            dataIndex: "reqs",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'reqs' ? sortDirection : false,
        },
        {
            title: "Operation",
            column_name:"operation",
            dataIndex: "operation",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'operation' ? sortDirection : false,
        },
        {
            title: "Org Name",
            column_name:"orgName",
            dataIndex: "orgName",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'orgname' ? sortDirection : false,
        },
        {
            title: "Category",
            column_name:"category",
            dataIndex: "category",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'category' ? sortDirection : false,
        },
        {
            title: "Info",
            column_name:"info",
            align: "center" as "center",
            render: (text: string, record: any) => (
                <Space>
                    {
                        <Tooltip title="View Info">
                            <Button
                                type="default" 
                                icon={<InfoOutlined />}
                               // onClick={(e) => { props.openInstallationProgressDrawer("Installations History for: (" + record.gatewayName + ' - ' + record.controllerName + ')', drawerWidth, record); }}
                            />
                        </Tooltip>
                    }
                </Space>
            )
        }//,

        // {
        //     title: "Actions",
        //     column_name:"actionList",
        //     //dataIndex: "orgName"
        //     align: "center" as "center",
        //     render: (text: string, record: any) =>(
        //         <>
        //             <div>
        //             {/* //{record.actionList.replace(/['"]+/g, '').map((tag:any) => {                         */}
        //             { record.actionList.substr(0, record.actionList.length - 1).substr(1).split(',').map((tag:any,index:any) => {
        //             return (
        //                 (tag === "")?
        //                 <div style={{cursor: "default"}}>{record.otaStatus}</div>
        //                 :
        //                 // <div>
        //                 // <Button type="primary" className = "buttonBorder" disabled={record.category.toLowerCase() != "approval required"?true:false}
        //                 // key={"actionButton"+index+"-"+tag} onClick={(e)=>initiateAction(e,record,actionType(tag))}>
        //                 //     {actionType(tag)}
        //                 // </Button>
        //                 // <Button type="primary" danger key={"actionButton"+index+"-"+tag} 
        //                 //     onClick={(e)=>initiateAction(e,record,"Deny Update")}>
        //                 //     Deny Update
        //                 // </Button>
        //                 // </div>
        //                 <div>
        //                     <Tooltip placement="top" title={actionType(tag)}>
        //                         <Button size="middle"  type="default" icon={<CheckSquareFilled />} style={{ marginLeft: '2px', color: '#44ce1b' }} onClick={(e)=>initiateAction(e,record,actionType(tag))}/>
        //                     </Tooltip>
        //                     <Tooltip placement="top" title="Deny Update">
        //                         <Button size="middle"  type="default" icon={<StopFilled />} style={{ marginLeft: '2px', color: '#e51f1f' }} onClick={(e)=>initiateAction(e,record,"Deny Update")}/>
        //                     </Tooltip>
        //                 </div>
                        
        //             );
        //             })}
        //             </div>
        //         </>
                   
        //     ) 
            
       // }
    ];


    
    let tableColumnswithAction = [
        {
            title: "Asset",
            column_name:"assetName",
            dataIndex: "assetName",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'assetname' ? sortDirection : false,
        },
        {
            title: "Status",
            column_name:"connectionStatus",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'connectionStatus' ? sortDirection : false,
            render: (text: any, record: any) => (
                            <>
                                 {
                                    record.connectionStatus === "ONLINE" && //record.status !== "inactive" && record.status !== "whitelisted" &&
                                    <Tooltip title="Online">
                                        <span className="asset-status-green alarm-info-icon pr-5" ><img src={onlineIcon} alt="" /></span>
                                    </Tooltip>
                                }
                                {/* {
                                    record.connectionStatus === "ONLINE" && record.status !== "active" &&
                                    <Tooltip title="Offline">
                                        <span className="asset-status-red alarm-info-icon pr-5" ><img src={offlineIcon} alt="" /></span>
                                    </Tooltip>
                                } */}
                                {
                                    record.connectionStatus === "OFFLINE" &&
                                    <Tooltip title="Offline">
                                        <span className="asset-status-red alarm-info-icon pr-5" ><img src={offlineIcon} alt="" /></span>
                                    </Tooltip>
                                }
                            </>
                        )
        },
        {
            title: <Tooltip mouseEnterDelay={0.5} data-title="Status Since" title="Represents most recent device's connect/disconnect timestamp"><span>Status Since</span></Tooltip>,
            column_name: "Status Since",
           // responsive: ["xl"] as Breakpoint[],
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            //sortOrder: this.state.assetTableFilters.sortColumn == 'statussince' ? this.state.assetTableFilters.sortDirection : false,
            align: "center" as "center",
            render: (text: any, record: any) => (
                <>                    
                {
                        record.connection_status === 'whitelisted' ?
                        <>{appConfigs.app.assets.offlineHavingNoDateText}</>
                        :record.disconnetedSince ?                          
                        <span>{new Date(record.disconnetedSince).toLocaleString()}</span> 
                        : <>{appConfigs.app.assets.offlineHavingNoDateText}</> 
                        //: <>{appConfigs.app.assets.onlineText}</>
                }
                </>
            )
        },
        {
            title: "Mastertag",
            column_name:"mastertag",
            dataIndex: "mastertag",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'mastertag' ? sortDirection : false,
        },
        {
            title: "ESN",
            column_name:"esn",
            dataIndex: "esn",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'esn' ? sortDirection : false,
        },
        {
            title: "Current Version",
            column_name:"currentVersion",
            dataIndex: "currentVersion",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'currentversion' ? sortDirection : false,
        },
        {
            title: "New Version",
            column_name:"newVersion",
           // dataIndex: "newVersion",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'newversion' ? sortDirection : false,
            render: (text: any, record: any) => (
                <>                    
                {  // <div> <span className="ribbon">{(record?.persistMode)? ((record.persistMode===2)?"TEST": "CMNS"):null}</span>
                    //     <span>{record.newVersion}</span>
                    // </div>   
                    <div> {(record?.persistMode)? ((record.persistMode===2)?<span className="ribbon">TEST</span>:""):""}
                         <span>{record.newVersion}</span>
                    </div>  
                                         
                }
                </>
            )
        },
        // {
        //     title: "Available Time",
        //     column_name:"availableTime",
        //     //dataIndex: "availableTime",
        //     sortDirections: ['ascend', 'descend', 'ascend'],
        //     sorter: true,
        //     sortOrder: sortColumn == 'availabletime' ? sortDirection : false,
        //     render: (text: any, record: any) => (
        //         <>                    
        //         {   
        //             <span>{moment(record.availableTime).format('MM/DD/YYYY, HH:mm:ss A').toLocaleString()}</span>      
        //         }
        //         </>
        //     )
        // },
        {
            title: "OTA Status",
            column_name:"otaStatus",
            //dataIndex: "otaStatus",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'otaStatus' ? sortDirection : false,
            render: (text: any, record: any) => (
                <>                    
                {   
                   (record.otaStatus === "Downloading to Engine" ||  record.otaStatus === "Buffering")?
                //    <div> <span>{record.otaStatus}</span><br/> 
                //    {/* <span style={{color:'#0052CC',fontSize:'12px'}}>{"Buffering might take 40 minutes (if the size is 3MB, it might take longer)"}</span> */}
                //    <span style={{color:'#0052CC',fontSize:'12px'}}>{"Buffering might take 40 minutes"}</span>
                //    </div>
                   <div>
                         <Tooltip title="Downloading might take some time">
                            <span>{record.otaStatus}</span>
                         </Tooltip>
                   </div>
                   :
                   <div> <span>{record.otaStatus}</span> </div>
                }
                    
                    
                </>
            )
        },
        {
            title: "Status At",
            column_name:"statusAt",
           // dataIndex: "statusAt",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'statusat' ? sortDirection : false,
            render: (text: any, record: any) => (
                <>                    
                {       
                    <span>{moment(record.statusAt).format('MM/DD/YYYY, h:mm:ss A').toLocaleString()}</span>    
                }
                </>
            )
        },
        {
            title: "Attempts",
            column_name:"attempts",
            dataIndex: "attempts",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'attempts' ? sortDirection : false,
        },
        {
            title: "Requests",
            column_name:"reqs",
            dataIndex: "reqs",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'reqs' ? sortDirection : false,
        },
        {
            title: "Operation",
            column_name:"operation",
            dataIndex: "operation",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'operation' ? sortDirection : false,
        },
        {
            title: "Org Name",
            column_name:"orgName",
            dataIndex: "orgName",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'orgname' ? sortDirection : false,
        },
        {
            title: "Category",
            column_name:"category",
            dataIndex: "category",
            sortDirections: ['ascend', 'descend', 'ascend'],
            sorter: true,
            sortOrder: sortColumn == 'category' ? sortDirection : false,
        },
        {
            title: "Info",
            column_name:"info",
            align: "center" as "center",
            render: (text: string, record: any) => (
                <Space>
                    {
                        <Tooltip title="View Info">
                            <Button
                                type="default" 
                                icon={<InfoOutlined />}
                                onClick={(e:any) => {calibrationMaintOpen(e, record)}}
                            />
                        </Tooltip>
                    }
                </Space>
            )
        },
        {
            title: "Actions",
            column_name:"actionList",
            //dataIndex: "orgName"
            align: "center" as "center",
            render: (text: string, record: any) =>(
                <>
                    <div>
                    {/* //{record.actionList.replace(/['"]+/g, '').map((tag:any) => {                         */}
                    { record.actionList.substr(0, record.actionList.length - 1).substr(1).split(',').map((tag:any,index:any) => {
                    return (
                        (tag === "" && (record.category.toLowerCase()!=="completed") && record?.actionList.toString()!=="[]")?
                        <div style={{cursor: "default"}}>{record.otaStatus}</div>
                        :
                        // <div>
                        // <Button type="primary" className = "buttonBorder" disabled={record.category.toLowerCase() != "approval required"?true:false}
                        // key={"actionButton"+index+"-"+tag} onClick={(e)=>initiateAction(e,record,actionType(tag))}>
                        //     {actionType(tag)}
                        // </Button>
                        // <Button type="primary" danger key={"actionButton"+index+"-"+tag} 
                        //     onClick={(e)=>initiateAction(e,record,"Deny Update")}>
                        //     Deny Update
                        // </Button>
                        // </div>
                        (record.category.toLowerCase() === "approval required")?
                        <div>
                            <Tooltip placement="top" title="Approve Update">
                                <Button size="middle" disabled={record.hasInProgressOta || disableAction} type="default" icon={<CheckSquareFilled />} style={{ marginLeft: '2px', color: '#44ce1b' }} onClick={(e)=>initiateAction(e,record,actionType(tag))}/>
                            </Tooltip>

                            <Tooltip placement="top" title="Deny Update">
                                <Button size="middle" disabled={record.hasInProgressOta || disableAction} type="default" icon={<StopFilled />} style={{ marginLeft: '2px', color: '#e51f1f' }} onClick={(e)=>initiateAction(e,record,"Deny Update")}/>
                            </Tooltip>
                        </div>
                        :
                        ((record.category.toLowerCase()==="in progress" || record.category.toLowerCase()==="interruptions")?
                        <div>
                            <Tooltip placement="top" title="Cancel Update">
                                <Button size="middle"  type="default"  disabled={record.otaStatus.toLowerCase().includes("cancel")  || disableAction }icon={<CloseSquareFilled />} style={{ marginLeft: '2px', color: '#808080' }} onClick={(e)=>initiateAction(e,record,"Cancel Update")}/>
                            </Tooltip>                           
                        </div>
                         :
                         ((record.category.toLowerCase()==="completed")?
                         (record.otaStatus.toLowerCase().includes("fail")) &&
                          //checking NRC22 condition to hide retry option in UI. OTA code for NRC 22 is 34.
                          !((record.info)?(parseInt(JSON.parse(record.info).otaCode) === 34 ):false) &&
                        <div>
                            <Tooltip placement="top" title="Retry Update">
                               <Button type="primary" onClick={(e)=>initiateAction(e,record,"Retry Failed Update")}>Retry</Button>
                            </Tooltip>
                        </div>
                         :""))
                        
                    );
                    })}
                    </div>
                </>
            )
        }
    ]
  
//  let testData = {actionList:"[1]",
//  assetName:"BMITCP5L",
//  availableTime:"2024-01-06 03:00:00+00",
//  boxId:"20332097340",
//  category:"In progress",
//  connectionStatus:"ONLINE",
//  csuJobId: null,
//  currentVersion:"PARKER3.00",
//  disconnetedSince: 1704449993721,
//  ecmModel: "6B S5 D067",
//  esn:"78000003",
//  esnCalReqId:"e85f0c00-ae94-11ee-ad41-b7c9e66c358d",
//  info: "{\"otaCode\": null, \"retries\": null, \"features\": \"CD,CSU\", \"otaMessage\": null, \"encryptionLevel\": \"2\", \"otStatusInternal\": null, \"telematicsVesion\": \"01030000\", \"telematicsSupplierId\": \"0\"}",
//  mac:"BMITCP5L",
//  mastertag:"BMITCP5L",
//  newVersion:"PARKER5.02",
//  operation: "update",
//  orgId: "c6adcb40-be92-11e6-9ed6-a5bc9cb5279b",
//  orgName:"Parker",
//  orgPathName:"Parker",
//  otaStatus:"Send Pending",
//  priority:"4",
//  recordNo:1,
//  status:"active",
//  tags:"[]",
//  totalRecords:7,
//  vin:"PARKERCSUTEST0003"}
   const sortAssetsTable = (pagination: any, filters: any, sorter: any) => {
        let sortColumn: string | undefined = undefined;
        let sortDirection: string | undefined = undefined;
        if (sorter && Object.keys(sorter).length > 0) {
            const { column, order } = sorter;
            sortDirection = order;
            if (column?.title?.hasOwnProperty('props')) {
                sortColumn = column?.title?.props['data-title'].toLowerCase().replace(/\s/g, '')
            } else {
                sortColumn = column?.title.toLowerCase().replace(/\s/g, '');
            }
        }

        setPage(pagination.current);
        setSortColumn(sortColumn || '');
        setSortDirection(sortDirection || '');
        //this.state.assetTableFilters.filters = filters?.tags?.length > 0 ? filters : {tags: null}
        //setAssetTableFilters(this.state.assetTableFilters)

        //this.setState({ isAssetTableFiltersSet: isAssetTableFiltersSet() });
        //this.props.getAssets(...this.getTableFilterParams());
       // props.getCalibrations(getOTAOnSuccess,getOTAOnError,null, sortColumn, sortDirection, props.search, category,0, page);

    }


    return(
        
        <>
        <div>
        {/* <div data-id="csu-container" className="layout-content">
            <Row gutter={8}>
                <Col xs={24} lg={8}>
                    <div className="content-header page-title">
                        <div style={{ display: "flex" }}>
                            <Tooltip title="Go To Previous Page">
                                <BackNavigation />
                            </Tooltip>
                            ECM OTA Management
                        </div>
                    </div>
                </Col>
                <Col xs={24} lg={14} className="content-header-controls">
                        <Space size="middle">
                            {
                                //(permissions.isRead) &&
                                <Search
                                    data-id="ecm-ota-search-field"
                                    key="search-field"
                                    placeholder="Search by esn, version, mastertag"
                                    enterButton="Search"
                                    size="middle"
                                    allowClear
                                    //onSearch={onSearch}
                                    //onChange={onSearchChange}
                                    className="search-field"
                                   // value={this.props.orgsList.searchText}
                                /> 
                            }                    
                        </Space>
                    </Col>
            </Row> */}

            {/* <Row gutter={8}>
                <Col xs={24} lg={8}>
                    <div className="content-header page-title">
                        <div style={{ display: "flex" }}>
                        <Button type="primary" className = "buttonBorder" 
                        key={actionType(1)} onClick={(e)=>initiateAction(e,testData,actionType(1))}>
                            {actionType(1)}
                        </Button>
                        </div>
                    </div>
                </Col>
                
            </Row> */}
            <Row gutter={8}>
                        <Col xs={24} lg={12} className="mt-10">
                            <span className="asset-status-green pr-5" ></span>
                            <Radio.Group value={category} buttonStyle="solid" onChange={onStatusChange}>

                                <Tooltip mouseEnterDelay={0.5} placement="bottom" title="ECMs with calibrations which require approval from the operator to initatiate installation" arrowPointAtCenter>
                                    <div style={{ display: 'inline-block', margin: '0 0 0 0', padding: '0 0 0 0', borderRadius: '0', border: '0'}}>
                                        <Radio.Button value="Approval Required">
                                            <PushpinOutlined className="pr-5" />Approval Required
                                        </Radio.Button>
                                    </div>
                                </Tooltip>

                                <Tooltip mouseEnterDelay={0.5} placement="bottom" title="ECMs with Calibrations in progress" arrowPointAtCenter>
                                    <div style={{ display: 'inline-block'}}>
                                        <Radio.Button value="In Progress">
                                            <Loading3QuartersOutlined className="pr-5" />In Progress
                                        </Radio.Button>
                                    </div>
                                </Tooltip>

                                <Tooltip mouseEnterDelay={0.5} placement="bottom" title="ECMs with calibrations completed" arrowPointAtCenter>
                                    <div style={{ display: 'inline-block'}}>
                                        <Radio.Button value="Completed">
                                            <LikeOutlined className="pr-5" />Completed
                                        </Radio.Button>
                                    </div>
                                </Tooltip>

                                <Tooltip mouseEnterDelay={0.5} placement="bottom" title="ECMs with upto Date" arrowPointAtCenter>
                                    <div style={{ display: 'inline-block'}}>
                                        <Radio.Button value="Up to date">
                                            <CheckCircleOutlined className="pr-5" />Up to date
                                        </Radio.Button>
                                    </div>
                                </Tooltip>
                                <Tooltip mouseEnterDelay={0.5} placement="bottom" title="ECMs with installation interuptions" arrowPointAtCenter>
                                    <div style={{ display: 'inline-block', margin: '0 0 0 0', padding: '0 0 0 0', borderRadius: '0', border: '0'}}>
                                        <Radio.Button value="Interruptions">
                                            <PushpinOutlined className="pr-5" />Interruptions
                                        </Radio.Button>
                                    </div>
                                </Tooltip>

                                <Tooltip mouseEnterDelay={0.5} placement="bottom" title="Show all ECMs regardless of type of calibration" arrowPointAtCenter>
                                    <div style={{ display: 'inline-block'}}>
                                        <Radio.Button value="All"
                                        >All</Radio.Button>
                                    </div>
                                </Tooltip>
                            </Radio.Group>
                        </Col>
                    </Row>

            <Row gutter={8}>
                <Table 
                        data-id="calibrations-data" 
                        className="mt-15"
                        style={{ overflowX: 'auto' }} 
                        {...tableConfig}
                        rowKey={(record:any) => record.gatewayId}
                        columns={(category ===  "Up to date")?tableColumns: tableColumnswithAction}
                        dataSource={tableData}
                        onChange={sortAssetsTable}
                        showSorterTooltip={false}
                    />
            </Row>
            <CalibrationMaint />
        </div>
        </>
    );
};

const mapStateToProps = (state: any) => {
    return {
      context: state.contextReducer.context,
      permissions: state.contextReducer.data,
      csus: state.csuReducer.csuState.csu.csuList.all
    };
  };
  
export default connect(mapStateToProps, actions)(CSU);
