import { produce as immerProduce } from "immer";
import { initialState } from "./state";

export function questionState(state = initialState, action:any) {
    let data:any = null,
        payload:any;
        switch (action.type) {
            case "QB_GET_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.questionBankList.refreshOnUpdate = false;
                    draft.questionBankList.tableData = data;
                    draft.questionBankList.tableConfig.pagination.total = (data.length || 0) > 0 ? data[0].totalRecords : 0;
                    draft.questionBankList.tableConfig.pagination.current = action.payload.page;
                });
            case "QB_MAINT_OPEN":
                return immerProduce(state, draft => {
                    const 
                        payload = action.payload,
                        instance = Math.random();
                    draft.questionBankList.qbMaint.instance = instance;
                    draft.questionBankList.qbMaint.width = payload.width;
                    draft.questionBankList.qbMaint.visible = true;
                    draft.questionBankList.qbMaint.title = payload.title;
                    draft.questionBankList.qbMaint.record = payload.record;

                    draft.questionBankList.qbMaint.activeTab = "1";
                    draft.questionBankList.qbMaint.tabs[0].disabled = true;
                    draft.questionBankList.qbMaint.tabs[0].instance = instance;
                });   
            case "QB_MAINT_CLOSE":
                return immerProduce(state, draft => {
                    draft.questionBankList.qbMaint.visible = false;
                });
            case "QB_SET_SEARCH":
                    return immerProduce(state, draft => {
                    draft.questionBankList.searchText = action.searchText;
            });
            case "QB_SET_PAGINATION":
                    return immerProduce(state, draft => {
                    draft.questionBankList.tableConfig.pagination.onChange = action.onPaginationChange;
            });
            case "GET_ASSETS_WITHOUT_QB_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.questionBankList.qbMaint.tabs[0].orgAssets = data;
                });
            case "QB_MAINT_SUCCESS":
                data = action.payload.data;
                return immerProduce(state, draft => {
                    draft.questionBankList.qbMaint.record = data;
                    draft.questionBankList.qbMaint.title = "Edit Question Bank :: " + data.questionBankName;
                });
            default:
                return state;
        }
}