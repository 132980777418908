import { PlusOutlined } from "@ant-design/icons";
import { Button, Divider, Form, Input, Select, Switch } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from "react";
import { connect } from "react-redux";
import { errorResponse, resetFormErrors, successResponse } from "./../../../../utils/apiHelpers/apiHelpers";
import { FormState, formInit } from "./../../../../utils/apiHelpers/formHelpers";
import { ErrorAlert, SuccessAlert } from "./../../../../utils/Components/formAlerts";
import { appConfigs } from "./../../../../utils/configurations";
import * as actions from "./../../QuestionManagement/actions";
import AddQuestion from "./AddQuestion";

const { Option } = Select;
interface Prop{
    questionBankList:any,
    qbMaint:Function,
    context: any,
    qbMaintClose:Function,
    getQbList: Function,
    getAssetsToAssignQuestionbank: Function,
    sortColumn: any,
    sortDirection: any,
    page:any
}

interface State extends FormState {
    formError?: boolean,
    formErrorMessage?: string[],
    formSuccess?: boolean,
    formSuccessMessage?: string,
    formWarning?: boolean;
    formWarningMessage?: string,
    formCommit?: boolean,
    initialValues: any,
    isOveridePanelDisplay: boolean;
    overrideErrorMessage: string;
    questionsList: string[];
    questionBankName: string,
    assetsAssigned: any,
    allowEvenForWrongAnswers: boolean
}

class QuestionBankMaintInfo extends React.Component<Prop, State> {
    state: State = {
        questionsList: [],
        initialValues: {},
        isOveridePanelDisplay: false,
        overrideErrorMessage: "",
        questionBankName: "",
        assetsAssigned: [],
        allowEvenForWrongAnswers: true
    };
    formRef = React.createRef<FormInstance>();
    componentDidMount() {
        this.componentUpdate(this.props.questionBankList.qbMaint.record);
    }
    componentDidUpdate(prevProps: Prop) {
        if (this.props.questionBankList.qbMaint.instance !== prevProps.questionBankList.qbMaint.instance) {
            this.componentUpdate(this.props.questionBankList.qbMaint.record);
        }
    }
    setInitialValues(id:any, questionBankName:any, assetsAssigned:any, mastertags:any, questionsList:any, allowEvenForWrongAnswers:any) {
        let initialValues: any = {};
        let assetData = mastertags?.split(',');
        let mastertagData = assetData?.map((assetTag:any) => {//let data = assetTag.split('|');
            return {value: assetTag.split('|')[2], label: assetTag.split('|')[2], key: assetTag.split('|')[0]}});
        initialValues = {...initialValues,
            questionBankName: questionBankName,
            qbId:id,
            //assetsAssigned: assetsAssigned,
            assetsAssigned: mastertagData, //assetData.map((assetTag:any) => {//let data = assetTag.split('|');
                    //return {value: assetTag.split('|')[2], label: assetTag.split('|')[2], key: assetTag.split('|')[0]}}),
            allowEvenForWrongAnswers: (allowEvenForWrongAnswers===undefined||allowEvenForWrongAnswers===null)?true:allowEvenForWrongAnswers
        }
        questionsList.forEach((question: any, index: any) => {
            initialValues = {...initialValues, [`question${index}`]: `${question}`}
        });
        // let assets = (assetsAssigned && assetsAssigned!==null && assetsAssigned!=="")?assetsAssigned.split(','):[];
        // let mastertagList = (mastertags && mastertags!==null && mastertags!=="")?mastertags.split(','):[];
        // //let assetsSNo: any[] = [];
        // const { orgAssets } = this.props.questionBankList.qbMaint.tabs[0];
        // if(orgAssets.length>0){        //     orgAssets.map((device:any, index:number) => {
        //         if(assets.length>0) {
        //         if(assets.includes(device.split('`')[0])){
        //                assetsSNo.push(device);
        //         }
        //     }
        //     })
        // }
        // initialValues = {...initialValues,
        //     assetsAssigned: assets.map((assetTag:any, index: any) => {return {value: mastertagList[index], label: assetTag, key: mastertagList[index]}})//assetsSNo
        // }
        this.setState({initialValues: initialValues,
                       questionsList: questionsList,
                       questionBankName: questionBankName,
                       allowEvenForWrongAnswers: allowEvenForWrongAnswers,
                        //assetsAssigned: assetsSNo//assetsAssigned
                       assetsAssigned: assetsAssigned //assetsSNo.split(',').map((assetTag:any) => {return {value: assetTag.split('`')[0], label: assetTag.split('`')[1], key: assetTag.split('`')[0]}})
        });
        return initialValues;
    }
    componentUpdate(record:any) {
        let orgId = 'a09ad270-3b21-11e7-893e-f94b4bc7246b';//only taylor Org
        const { orgAssets } = this.props.questionBankList.qbMaint.tabs[0];
        if(orgAssets.length === 0) {
            this.props.getAssetsToAssignQuestionbank(this, null, null, 'all', orgId);
        }
        formInit(this);
        let initialData = this.setInitialValues(record.qbId,record.questionBankName,record.assetsAssigned,record.mastertags,record.questions&&record.questions!==""?record.questions.toString().split(','):[], record.allowEvenForWrongAnswers);
        if (this.formRef.current != null) {
            this.formRef.current!.setFieldsValue(initialData);
        }
    }

    onFinish = (values: any) => {
        resetFormErrors(this, this.formRef.current);
    //     let assetsAssigned:any[] = []
    //    const { orgAssets } = this.props.questionBankList.qbMaint.tabs[0];
    //     values.assetsAssigned.map((mastertag: any) => {
    //         orgAssets.map((asset:any) => {
    //             if (asset.serialNumber === mastertag.value ) {
    //                 assetsAssigned.push({key:asset.mastertag , label:asset.serialNumber , value:asset.mastertag });
    //             }
    //             else
    //             {
    //                 assetsAssigned.push(mastertag);
    //             }
    //     });
    //     });
    //     values.assetsAssigned= assetsAssigned;
        this.props.qbMaint(this, this.onFinishSuccess, this.onFinishFailure, values);
    };

    // orgAssetSuccess = (data:any) => {
    //     let record = this.props.questionBankList.qbMaint.record;
    //     this.setInitialValues(record.qbId,record.questionBankName,record.assetsAssigned,record.mastertags,record.questions&&record.questions!==""?record.questions.toString().split(','):[], record.allowEvenForWrongAnswers);

    // }

    onFinishSuccess = (record: any) => {
        if (record != null && record.qbId != null) {
            let orgId = 'a09ad270-3b21-11e7-893e-f94b4bc7246b';//only taylor Org
            this.componentUpdate(record);
            this.props.getAssetsToAssignQuestionbank(this, null, null, 'all', orgId);
            //this.props.getQbList(this, null, null, this.props.questionBankList.tableConfig.pagination.current, null,null);
            this.props.getQbList(this, null, null, this.props.sortDirection, this.props.sortColumn, this.props.page, null, null);
        }
        successResponse(this, "Question Bank has been successfully processed");
    }
    onFinishFailure = (error: any) => {
            errorResponse(this, error);
    } 

    handleChange = (value: string) => {
        this.setState({assetsAssigned: value});
    };

    onAllowAlwaysChange = (e:any) => {
        let initialData = this.state.initialValues;
        initialData = {...initialData,
            allowEvenForWrongAnswers: e}
        this.setState({allowEvenForWrongAnswers: e,
            initialValues: initialData
        });
        //this.formRef.current!.setFieldsValue(initialData);
    }
    AddQuestion=()=>
    {
        let {questionsList, initialValues} = this.state;
        initialValues = {...initialValues, ["qbId"]: this.formRef.current?.getFieldValue("qbId"),
            ["questionBankName"]: this.formRef.current?.getFieldValue("questionBankName"),
            ["assetsAssigned"]: this.formRef.current?.getFieldValue("assetsAssigned")
        };
        let len = (questionsList.length===0)?1:questionsList.length;
            for(let i=0;i<len;i++){
                let question = this.formRef.current?.getFieldValue(`question${i}`) || "";
                questionsList[i] = question;
                initialValues = {...initialValues, [`question${i}`]: `${question}`};
            }
        // }
        let newQuestion = "";
        questionsList.push(newQuestion);
        initialValues = {...initialValues, [`question${questionsList.length-1}`]: `${newQuestion}`};
        this.setState({
            questionBankName: this.formRef.current?.getFieldValue("questionBankName"),
            assetsAssigned: this.formRef.current?.getFieldValue("assetsAssigned"),
            initialValues: initialValues
        });
        this.formRef.current!.resetFields();
        this.formRef.current!.setFieldsValue(initialValues);
    }
    RemoveQuestion=(deletedIndex:any)=>
    {

        let {questionsList, initialValues} = this.state;
        initialValues = {...initialValues, ["qbId"]: this.formRef.current?.getFieldValue("qbId"),
            ["questionBankName"]: this.formRef.current?.getFieldValue("questionBankName"),
            ["assetsAssigned"]: this.formRef.current?.getFieldValue("assetsAssigned")
        };
        for(let i=0;i<questionsList.length;i++){
            let question = this.formRef.current?.getFieldValue(`question${i}`) || "";
            questionsList[i] = question;
            delete initialValues[`question${i}`];
        }
        questionsList.splice(deletedIndex,1);
        questionsList.map((question:any, index: number) => {
            initialValues = {...initialValues, [`question${index}`]: `${question}`};
        });
        this.setState({
            initialValues : initialValues,
            questionsList: questionsList,
        })
        this.formRef.current!.resetFields();
        this.formRef.current!.setFieldsValue(initialValues);
    }

    render(){
        const { orgAssets } = this.props.questionBankList.qbMaint.tabs[0];
        const {assetsAssigned, qbId} = this.props.questionBankList.qbMaint.record;
        const questionBankName = this.state.questionBankName;
        let questions = this.state.questionsList;
        return(
            <div  data-id="qb-maint-info">
                {this.state.formSuccess && !this.state.formError && <SuccessAlert data-id="qbList-maint-info-success-message"  message={this.state.formSuccessMessage}/>}
                {this.state.formError && !this.state.formSuccess && <ErrorAlert data-id="qbList-maint-info-error-message" message={this.state.formErrorMessage}/>}

           <Form
            {...appConfigs.settings.formLayout.mainLayout}
            id="qbMaintInfoForm"
            ref={this.formRef}
            onFinish={this.onFinish}
            className="mt-15"
        >
           <Form.Item
                name="qbId"
                style={{ display: 'none' }}
            >
                <Input type="hidden" />
            </Form.Item>

            <Form.Item
                label="Name"
                name="questionBankName"
                rules={[{ required: true, message: appConfigs.errors.fieldErrors.valueRequired }]}
            >
                <Input maxLength={50} />
            </Form.Item>

            {/* <Form.Item
                    label="Assigned Assets"
                    name="assetsAssigned"

                    initialValue={(assetsAssigned == null || !assetsAssigned) ? [] : (assetsAssigned.toString()?.split(","))?.map((asset: any) => {
                             return { value: asset, label: asset, key: asset }
                             })}
                >            
                <Select
                        style={{ width: '100%'}}
                        placeholder="Select mastertags"
                        onChange={this.handleChange}
                        className="select-before" 

                    >
                        {orgAssets !== null && orgAssets.map((asset:any, index:number) => {
                            return (
                            <Option key={asset.split('`')[0]} value={asset.split('`')[0]}>
                                {asset.split('`')[1]}
                            </Option>
                        )})}
            </Select>
            </Form.Item>
            */}

            <Form.Item
                    label="Assigned Assets"
                    name="assetsAssigned"
                
                    initialValue={assetsAssigned == null ? [] : assetsAssigned?.split(',').map((asset: any) => {
                             return { value: asset, key: asset }
                             })}
                >
                <Select

                        mode="multiple"
                        style={{ width: '100%'}}
                        placeholder="Select Serial Number"
                        // disabled={((this.state.accessType ||"") === "RFID" ||
                        //     (accessType === "RFID" && (this.state.accessType || "").toLowerCase() !== "asset-mapping")) ||
                        //     this.props.oprList.oprMaint.record.status == 'inactive' }
                         labelInValue
                        showSearch
                        showArrow={true}
                        allowClear
                        // optionFilterProp="children"
                        className="select-before"

                    >
                        {orgAssets !== null && orgAssets.map((asset:any, index:number) => {
                            return (
                            <Option key={asset.mastertag} value={asset.serialNumber}>
                                {asset.serialNumber}
                            </Option>
                        )})}
                </Select>
                </Form.Item>
                <Form.Item name="allowEvenForWrongAnswers" label="Allow users to Login even if answered wrong: " style={{"width": '1500px'}}>
                
                {/* <Tooltip placement="topRight" title="Allow"> */}
                        
                                    {/* <Form.Item style={{marginTop:'-15px',flexDirection:'row',marginLeft:'535px'}}   label="Replace Asset: " valuePropName="checked"> */}
                    <Switch checked={(this.state.allowEvenForWrongAnswers===undefined||this.state.allowEvenForWrongAnswers===null)?true:this.state.allowEvenForWrongAnswers} onChange={this.onAllowAlwaysChange} style={{margin:'0px 0px 5px 5px'}}/>

                                    {/* </Form.Item> */}
                        
                {/* </Tooltip> */}
            
                </Form.Item>
               
                <Divider orientation="left" plain>Questions</Divider>
                    <div>
                        <div style = {{ marginLeft: '670px'}}>
                            <Button type="primary" onClick={this.AddQuestion} disabled={questions && questions !== undefined && questions?.length>14} icon={<PlusOutlined></PlusOutlined>}></Button>
                        </div>
                        <br/>
                        <div style = {{ marginLeft: '50px'}}>
                        {
                            questions?.length>0 ?
                            (questions?.map((question:any,index: any)=>
                            <><AddQuestion key={"question" + index} remove={this.RemoveQuestion} question={question} index={index}></AddQuestion></>
                            
                            ))
                            :
                            (<><AddQuestion key={"question" + 0} remove={this.RemoveQuestion} index={0} question={null}></AddQuestion></>
                            )
                        }
                        </div>
                    </div>

            </Form>

        </div>
        );
    }
}

const mapStateToProps = (state:any) => {
    return {
        context: state.contextReducer.context,
        questionBankList: state.questionsReducer.questionState.questionBankList
    };
};

export default connect(
    mapStateToProps,
    actions
)(QuestionBankMaintInfo);