import { ServiceTruck } from './../pages/FleetOverview2/models';
import { combineReducers } from "redux";
import { contextReducer } from "./contextReducer";
import { supportModeReducer } from "./supportModeReducer";
import { loginReducer } from "../pages/Login/reducers";
import { fleetOverviewReducers } from "../pages/FleetOverview2/reducers";
import { usersReducers } from "../pages/Users/reducers";
import { rolesReducer } from "../pages/Roles/reducers";
import { orgsReducers } from "../pages/Orgs/reducers";
import { countriesReducer } from "../pages/Countries/reducers";
import { orgSelectorReducers } from "../pages/OrgSelector/reducers";
import { assetsReducer } from "../pages/Assets/reducers";
import { userControllersReducers } from "../pages/Controllers/reducers";
import { alertsReducer } from "../pages/Alerts/reducers";
import { templatesReducers } from "../pages/Templates/reducers";
import { templateDetailsReducers } from "../pages/TemplateDetails/reducers";
import { reportReducer } from "./reportReducer";
import { dashboardReducer } from "./dashboardReducer";
import { userOrgAccessReducer } from "./userOrgAccessReducer";
import { permissionsReducer } from "./permissionsReducer";
import { oprReducers } from "../pages/Operators/reducers";
import { reportSchedulesReducers } from "../pages/ReportSchedules/reducers";
import { geofencesReducer } from "../pages/GeoFences/reducers"
import { serviceTruckReducer } from "../pages/ServiceTrucks/reducers"
import { tutorialsReducers } from "../pages/Help/VideoTutorials/reducers"
import { contractsReducers } from "../pages/Admin/Contracts/reducers"
import { controllersReducers } from "../pages/Admin/Controllers/reducers"
import { applicationsReducers } from "../pages/Admin/Applications/reducers"
import { softwaresReducers } from "../pages/Iqanopen/Softwares/reducers";
import { DeviceReducers } from "../pages/Iqanopen/UpdateDeviceSoftware/reducers"
import { csuReducer } from "../pages/CSU/reducers"
import { releasenotesReducers } from "../pages/Help/ReleaseNotes/reducers"
import { userguideReducer } from "../pages/Help/UserGuide/reducers"
import { taylorOprReducers } from "../pages/TaylorAccess/OperatorManagement/reducers"
import { questionsReducer } from "./../pages/TaylorAccess/QuestionManagement/reducers"

const rootReducer = combineReducers({
    contextReducer,  
    supportModeReducer,
    loginReducer,
    fleetOverviewReducers,
    usersReducers,
    rolesReducer,
    orgsReducers,
    countriesReducer,
    orgSelectorReducers,
    assetsReducer,
    userControllersReducers,
    alertsReducer,
    templatesReducers,
    templateDetailsReducers,
    dashboardReducer,
    reportReducer,
    userOrgAccessReducer,
    permissionsReducer,
    oprReducers,
    reportSchedulesReducers,
    geofencesReducer,
    serviceTruckReducer,
    tutorialsReducers,
    contractsReducers,
    controllersReducers,
    applicationsReducers,
    softwaresReducers,
    DeviceReducers,
    csuReducer,
    releasenotesReducers,
    userguideReducer,
    taylorOprReducers,
    questionsReducer,

}); 

export default rootReducer;

