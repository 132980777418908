import Axios from "axios";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { formatTagsForPosting } from "../../../utils/commonHelpers";
import { appConfigs } from "../../../utils/configurations";

export function operatorOrgsGet(
    me: any, 
    callbackFnSuccess?: any,
    callbackFnFailure?: any,
    orgId?: string

) {
    return async (dispatch: any) => {            
        Axios.get(
            appConfigs.server.URL + "/ui/api/orgs-lov", {
                responseType: "json",        
                headers: {},
                params: {
                    mode: (orgId || "") === "" ? "administered" : "single",
                    scope: "all",
                    includeTree: false,
                    page: 1,
                    pageSize: 9999
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgs);
                }
                dispatch({ type: "OPR_HOME_ORGS_SUCCESS", payload: {data: response.data.data.orgs} });
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFnFailure != null) {
                    callbackFnFailure();
                }
            }
        );
    };
}    

export function operatorOrgUsersGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    orgId: string,
   
) {

    return async (dispatch: any) => {            
        Axios.get(
            // appConfigs.server.URL + "/ui/api/orgs/" + orgId + "/users", 
            // {
            //     responseType: "json",        
            //     headers: {},
            //     params: {
            //         role: "admin",
            //         pageSize: appConfigs.tables.pageSize
            //     }
            // }
            appConfigs.server.URL + "/ui/api/users/okta", {
                responseType: "json",        
                headers: {},
                params: {
                    orgId:orgId,
                    administered: false,
                    pageSize: 10000
                }
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.users);
                }
                dispatch({ type: "OPR_MAINT_ORG_USERS_GET_SUCCESS", payload: {data: response.data.data.users} });
            }
        )
        .catch(function (error) {
            errorResponse(me, error, false);
            if (callbackFnFailure != null) {
                callbackFnFailure(error);
            }
        });
    };
}

export function operatorOrgAssetsGet(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    status: string,
    orgId: string,
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/control-assets?page=1&pageSize=9999", {
                responseType: "json",
                headers: {},
                params: {
                    orgId: orgId
                }
            }
        )
        .then(response => {
                let data = (response.data.data.orgAssets == null || response.data.data.orgAssets.equipment == null ||
                        (response.data.data.orgAssets.equipment.length || 0) === 0 ) ? [] : response.data.data.orgAssets.equipment;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.orgAssets.equipment);
                }
            
                data = data.map((tag: any) => tag['mastertag']).filter((item: any, i: any, ar: any) => ar.indexOf(item) === i).sort();
    
                dispatch({ type: "OPR_MAINT_ORG_ASSETS_GET_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}


export function operatorOrgAccessTypesGet(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    status: string,
    orgId: string,
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/org-operator-access-types", {
                responseType: "json",        
                headers: {},
                params: {
                    orgId: orgId
                }
            }
        )
        .then(response => {  
                let data = (response.data.data.accessTypes == null ||
                        (response.data.data.accessTypes.length || 0) === 0 ) ? [] : response.data.data.accessTypes;
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.accessTypes);
                }
            
                data = data.map((tag: any) => tag['accessType']).filter((item: any, i: any, ar: any) => ar.indexOf(item) === i).sort();
    
                dispatch({ type: "OPR_MAINT_ORG_ACCESS_TYPES_GET_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function rfidSetValue(
    me: any,
    value: any
) {
    return async (dispatch: any) => {
        dispatch({ type: "OPR_RFID_SET_FIELD_VALUE", payload: {data: value}});           
    }   
}

export function oprMaint(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    record: any,
    operatorHistoryId?:string,
    isOverride?:boolean
) {
 
    return async (dispatch: any) => {
        record.mastertags = formatTagsForPosting(record.mastertags);
  
        let url="/ui/api/operators?override="+isOverride+"&operatorHistoryId=" + (operatorHistoryId || "")
        Axios.post(
            appConfigs.server.URL + url,
            record,
            {
                responseType: "json",       
                headers: {}
            }
        )
        .then(response => {
    
            let data = response.data.data.operator == null ? {} : response.data.data.operator;
                
            data['mastertags'] = JSON.parse(data['mastertags']);
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "OPR_MAINT_SUCCESS", payload: {data: data} });
            }
        )
        .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(error);
                }
            }
        );
        }
}

export function oprMaintTabsEnable(
    me: any
) {
    return (dispatch: any) => {
        dispatch({ type: "OPR_MAINT_TABS_ENABLE" });
    };
}

export function operatorDelete(
    me: any, 
    callbackSuccessFn: any,
    callbackFailureFn: any,
    operatorId:string
    
) {
    return async (dispatch: any) => {
        Axios.delete(
            appConfigs.server.URL + "/ui/api/operators/" + operatorId,
            {}
        )
        .then(response => {
                if (callbackSuccessFn != null) {
                    callbackSuccessFn(response);           
                }
            }
        )
        .catch(function (error) {
                errorResponse(me, error, false);
                if (callbackFailureFn != null) {
                    callbackFailureFn(error);
                }
            }
        );        
    };
}

export function getOperatorsForExport(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    searchText?: string,

) {

    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/operators.csv", {
            headers: {},
             params: {
                 searchText: searchText,
             }
        }
        )
            .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data);
                }

            }
            )
            .catch(function (error) {
                if (callbackFnFailure != null) {
                    callbackFnFailure(me, error)
                }
                errorResponse(me, error);
            });
    };
}