import Axios from "axios";
import { errorResponse } from "../../../utils/apiHelpers/apiHelpers";
import { appConfigs } from "../../../utils/configurations";

export function getOprs(
    me: any,
    callbackFnSuccess: any,
    callbackFnFailure: any,
    page: number = 1,
    searchText?: string,
    operatorId?:string

) {
    
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/operators", {
                responseType: "json",        
                headers: {},
                params: {
                    searchText: searchText,
                    page: page,
                    pageSize: appConfigs.tables.pageSize,
                    operatorId: (operatorId||"")
                }
            }
        )
        .then(response => { 
                let data = response.data.data.operators == null || (response.data.data.operators.length || 0) === 0 ? [] : response.data.data.operators;
 
                data.forEach((item: any, index: number) => {
                    data[index]['mastertags'] = JSON.parse(data[index]['mastertags'])
                    // data[index]['mastertags'] = data[index]['mastertags']?.sort(sortAlphabetically)
                });
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(data);
                }
                dispatch({ type: "OPRS_GET_SUCCESS", payload: {data: data, page: page} });
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error)
            }
            errorResponse(me, error);
        });
    };
}

export function getOpr(
    me: any, 
    callbackFnSuccess: any,
    callbackFnFailure: any,
    operatorId: string
) {
    return async (dispatch: any) => {
        Axios.get(
            appConfigs.server.URL + "/ui/api/operators/id/" + operatorId, {
                responseType: "json",        
                headers: {},
                params: {}
            }
        )
        .then(response => {
                if (callbackFnSuccess != null) {
                    callbackFnSuccess(response.data.data.operator);
                }
            }
        )
        .catch(function (error) {
            if (callbackFnFailure != null) {
                callbackFnFailure(me, error);
            }
            errorResponse(me, error);
        });
    };
}

export function oprMaintOpen(
    me: any, 
    title: string,
    width: number,
    record: any
) {
    return (dispatch: any) => {
        dispatch({ type: "OPRS_OPR_MAINT_OPEN", payload: {title: title, width: width, record: record} });
    };
}

export function oprMaintClose(
    me: any
) {   
    return (dispatch: any) => {
        dispatch({ type: "OPRS_OPR_MAINT_CLOSE" });
    };
}

export function oprSetSearch(
    searchText: string 
) {
    return (dispatch: any) => {
        dispatch({ type: "OPR_SET_SEARCH", searchText: searchText });
    }
}   

export function oprSetPagination(
    onPaginationChange: any 
) {
    return (dispatch: any) => {
        dispatch({ type: "OPRS_SET_PAGINATION", onPaginationChange: onPaginationChange });
    }
} 

export function oprsListRefreshOnUpdate(
    me: any
) {
    return (dispatch: any) => {
        dispatch({ type: "OPRS_LIST_REFRESH_ON_UPDATE" });
    };
}

export function clearTableData(
    me: any
) {
    return (dispatch: any) => {
        dispatch({ type: "OPRS_LIST_CLEAR", payload: {data: [], page: 1} });
    };
}

